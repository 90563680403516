import './FAQ.css'
import { Accordion } from "react-bootstrap";
// /Accordion
import { useTranslation } from "react-i18next";

export default function FAQ() {

  const { t } = useTranslation();

  return (
    <div>

      {/* <!-- ======= Hero Section ======= --> */} 
      <section className="hero-section" id="hero">
        <div className="container">
          <div className="row align-items-center">
            <div className="col-12 hero-text-image">
              <div className="row">
                <div className="col-lg-8 text-center text-lg-start">
                  <h1 data-aos="fade-right">{t("faq_h1_part1")} <br />
                    {t("faq_h1_part2")}</h1>
                  <p className="mb-5" data-aos="fade-right" data-aos-delay="100">{t("faq_subhead_p")}</p> 
                </div>
                <br />
              </div>
            </div>
          </div>
        </div>
      </section>{/* <!-- End Hero --> */}

      <main id="main">

      {/* <!-- ======= Home Section ======= --> */} 
        <section className="section faq">
          <div className="container">
            <div className="row justify-content-center text-center mb-5">
              <div className="col-md-5" data-aos="fade-up">
                <h2 className="section-heading faq-h2" id="get-started">{t("faq_h2_faq")}</h2>
                <p> ({t("faq_p_faq")})</p>               
              </div>
            </div>
          </div>
        </section>

      <br />

      {/* <!-- FAQ Section --> */}
      
        <section className="faq-container" id="about">
          <div className="container">
            <div className="accordion" id="accordionExample">
              <Accordion defaultActiveKey="0">
                <Accordion.Item eventKey="0">
                  <Accordion.Header><strong> {t("faq_q1_title")} </strong></Accordion.Header>
                  <Accordion.Body>
                  {t("faq_q1_body")}
                  </Accordion.Body>
                </Accordion.Item>

                <Accordion.Item eventKey="1">
                  <Accordion.Header><strong>{t("faq_q2_title")} </strong></Accordion.Header>
                  <Accordion.Body>
                  {t("faq_q2_body")}
                  </Accordion.Body>
                </Accordion.Item>

                <Accordion.Item eventKey="2">
                  <Accordion.Header><strong>{t("faq_q3_title")} </strong></Accordion.Header>
                  <Accordion.Body>
                  {t("faq_q3_body")}
                  </Accordion.Body>
                </Accordion.Item>

                <Accordion.Item eventKey="3">
                  <Accordion.Header><strong>{t("faq_q4_title")} </strong></Accordion.Header>
                  <Accordion.Body>
                  {t("faq_q4_body")}
                  </Accordion.Body>
                </Accordion.Item>

                <Accordion.Item eventKey="4">
                  <Accordion.Header><strong>{t("faq_q5_title")} </strong></Accordion.Header>
                  <Accordion.Body>
                  {t("faq_q5_body")} 
                  </Accordion.Body>
                </Accordion.Item>

                <Accordion.Item eventKey="5">
                  <Accordion.Header><strong>{t("faq_q6_title")} </strong></Accordion.Header>
                  <Accordion.Body>
                  {t("faq_q6_body")}
                  </Accordion.Body>
                </Accordion.Item>

                <Accordion.Item eventKey="6">
                  <Accordion.Header><strong>{t("faq_q7_title")} </strong></Accordion.Header>
                  <Accordion.Body>
                  {t("faq_q7_body")}
                  </Accordion.Body>
                </Accordion.Item>

                <Accordion.Item eventKey="7">
                  <Accordion.Header><strong>{t("faq_q8_title")}  </strong></Accordion.Header>
                  <Accordion.Body>
                  {t("faq_q8_body")}
                  </Accordion.Body>
                </Accordion.Item>

                <Accordion.Item eventKey="8">
                  <Accordion.Header><strong>{t("faq_q9_title")} </strong></Accordion.Header>
                  <Accordion.Body>
                  {t("faq_q9_body")}
                  </Accordion.Body>
                </Accordion.Item>

                <Accordion.Item eventKey="9">
                  <Accordion.Header><strong>{t("faq_q10_title")} </strong></Accordion.Header>
                  <Accordion.Body>
                  {t("faq_q10_body")}
                  </Accordion.Body>
                </Accordion.Item>

                <Accordion.Item eventKey="10">
                  <Accordion.Header><strong>{t("faq_q11_title")} </strong></Accordion.Header>
                  <Accordion.Body>
                  {t("faq_q11_body")}
                  </Accordion.Body>
                </Accordion.Item>

                <Accordion.Item eventKey="11">
                  <Accordion.Header><strong>{t("faq_q12_title")} </strong></Accordion.Header>
                  <Accordion.Body>
                  {t("faq_q12_body")}
                  </Accordion.Body>
                </Accordion.Item>

                <Accordion.Item eventKey="12">
                  <Accordion.Header><strong>{t("faq_q13_title")} </strong></Accordion.Header>
                  <Accordion.Body>
                  {t("faq_q13_body")}
                  </Accordion.Body>
                </Accordion.Item>

                <Accordion.Item eventKey="13">
                  <Accordion.Header><strong>{t("faq_q14_title")} </strong></Accordion.Header>
                  <Accordion.Body>
                  {t("faq_q14_body")}
                  </Accordion.Body>
                </Accordion.Item>

                <Accordion.Item eventKey="14">
                  <Accordion.Header><strong>{t("faq_q15_title")} </strong></Accordion.Header>
                  <Accordion.Body>
                  {t("faq_q15_body")}
                  </Accordion.Body>
                </Accordion.Item>

                <Accordion.Item eventKey="15">
                  <Accordion.Header><strong>{t("faq_q16_title")} </strong></Accordion.Header>
                  <Accordion.Body>
                  {t("faq_q16_body")}
                  </Accordion.Body>
                </Accordion.Item>

                <Accordion.Item eventKey="16">
                  <Accordion.Header><strong>{t("faq_q17_title")} </strong></Accordion.Header>
                  <Accordion.Body>
                  {t("faq_q17_body")}
                  </Accordion.Body>
                </Accordion.Item>

                <Accordion.Item eventKey="17">
                  <Accordion.Header><strong>{t("faq_q18_title")} </strong></Accordion.Header>
                  <Accordion.Body>
                  {t("faq_q18_body")}
                  </Accordion.Body>
                </Accordion.Item>

                <Accordion.Item eventKey="18">
                  <Accordion.Header><strong>{t("faq_q19_title")} </strong></Accordion.Header>
                  <Accordion.Body>
                  {t("faq_q19_body")}
                  </Accordion.Body>
                </Accordion.Item>

                <Accordion.Item eventKey="19">
                  <Accordion.Header><strong>{t("faq_q20_title")} </strong></Accordion.Header>
                  <Accordion.Body>
                  {t("faq_q20_body")}
                  </Accordion.Body>
                </Accordion.Item>

              </Accordion>
            </div>
          </div>
        </section>

      </main>{/* <!-- End #main --> */}

    </div>
  )
}
 