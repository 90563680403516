import React from 'react';
import { select, on } from './element-selector.js';

export const HamburgerNav = () => {
  React.useEffect(() => {
    const handleClick = (event) => {
      const target = event.target;

      // Mobile nav toggle
      if (target.matches('.mobile-nav-toggle')) {
        select('#header').classList.toggle('navbar-mobile');
        select('#header').classList.toggle('navbar');
        select('#DropdownButton').classList.toggle('dropdown-button-mobile');
        select('.DropdownElement').classList.toggle('Dont-Display');
        target.classList.toggle('bi-list');
        target.classList.toggle('bi-x');
      }

      // TO CLOSE THE HAMBURGER NAV AFTER CLICKING LINK TO NEW PAGE
      if (target.matches('#close-nav')) {
        select('#header').classList.remove('navbar-mobile');
        select('#header').classList.add('navbar');
        select('.mobile-nav-toggle').classList.remove('bi-x');
        select('.mobile-nav-toggle').classList.add('bi-list');
      }
    };

    window.addEventListener('click', handleClick);

    // Cleanup this component
    return () => {
      window.removeEventListener('click', handleClick);
    };
  }, []);
};
