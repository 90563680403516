import TutorialNavbar from '../../components/TutorialNavbar.js'

// styles
import './Tutorials.css'

// images import 
import Image1 from '../../assets/img/tutorial-screenshots/create-a-geofence/step1-login-screen.png'
import Image2 from '../../assets/img/tutorial-screenshots/create-a-geofence/step2-geofence.png'
import Image3 from '../../assets/img/tutorial-screenshots/create-a-geofence/step3-safetyzone.png'
import Image4 from '../../assets/img/tutorial-screenshots/create-a-geofence/step4-safetyzone2.png'
import Image5 from '../../assets/img/tutorial-screenshots/create-a-geofence/step5-allow.png'
import Image6 from '../../assets/img/tutorial-screenshots/create-a-geofence/step6-address.png'
import Image7 from '../../assets/img/tutorial-screenshots/create-a-geofence/step7-checkAddress.png'
import Image8 from '../../assets/img/tutorial-screenshots/create-a-geofence/step8-confirm.png'

import { useTranslation } from "react-i18next";

export default function TutorialCreateSafetyZone() {

  const { t } = useTranslation();

  return (
    <div>

        {/* <!-- ======= Hero Section ======= --> */}
        <section className="hero-section" id="hero">

          

          <div className="container">
            <div className="row align-items-center">
              <div className="col-12 hero-text-image">
                <div className="row">
                  <div className="col-lg-8 text-center text-lg-start">
                    <h1 data-aos="fade-right">{t("tutorial_create_geofence_h1")} <br /> </h1>

                    <p className="mb-5" data-aos="fade-right" data-aos-delay="100">{t("tutorial_create_geofence_subhead_p")}</p>
                    {/* <!--   <p data-aos="fade-right" data-aos-delay="200" data-aos-offset="-500"><a href="#get-started" className="btn btn-outline-white">Get Started</a></p> --> */}
                  </div>   
                </div>
              </div>
            </div>
          </div>

        </section> {/* <!-- End Hero --> */}

        {/* Tutorial Navbar Import */}
        <TutorialNavbar />

        <main id="main">

          {/* <!-- ======= Tutorial Section ======= --> */}

          {/* <!-- ======= Tutorial Create a Geofence (Safety Zone) ======= --> */}
          <section className="section" id="get-started">

            <div className="container tutorial-flex" id="create-a-geofence" >

              <div className="row justify-content-center tutorial-top-container" data-aos="fade">

                <div className="tutorial-container">

                  <h2 className="section-heading">{t("tutorial_create_geofence_h2")}</h2>

                  <div className="tutorial-content">
                   
                    <div className="tutorial-step tutorial-text-box">

                      <h3>{t("tutorial_create_geofence_purpose_h3")}</h3>
                      <p>{t("tutorial_create_geofence_purpose_p_part1")}
                        <br /><br />
                        {t("tutorial_create_geofence_purpose_p_part2")}
                        <br /><br />
                      <h3>{t("tutorial_create_geofence_requirement_h3")}</h3>
                      {t("tutorial_create_geofence_requirement_p")} 
                      </p>

                      <br />
                      <h3>{t("tutorial_create_geofence_step1_h3")}</h3>
                      <p>{t("tutorial_create_geofence_step1_p")}</p>
                    </div>
                  </div>

                  <div className="tutorial-img-container">
                    <img src={Image1} alt="icon" className="img-fluid tutorial-img" />
                  </div>

                  <div className="tutorial-content">

                    <div className="tutorial-step tutorial-text-box">

                      <h3>{t("tutorial_create_geofence_step2_h3")} </h3>
                      <p>{t("tutorial_create_geofence_step2_p")}</p>
                    </div>
                  </div>

                  <div className="tutorial-img-container">
                    <img src={Image2} alt="icon" className="img-fluid tutorial-img" />
                  </div>

                  <div className="tutorial-content">

                    <div className="tutorial-step tutorial-text-box">

                      <h3>{t("tutorial_create_geofence_step3_h3")} </h3>
                      <p>{t("tutorial_create_geofence_step3_p")}</p>
                    </div>
                  </div>

                  <div className="tutorial-img-container">

                    <img src={Image3} alt="icon" className="img-fluid tutorial-img" />
                  </div>

                  <div className="tutorial-content">

                    <div className="tutorial-step tutorial-text-box">

                      <h3> {t("tutorial_create_geofence_step4_h3")} </h3>
                      <p>{t("tutorial_create_geofence_step4_p")}</p>
                    </div>
                  </div>

                  <div className="tutorial-img-container">
                    <img src={Image4} alt="icon" className="img-fluid tutorial-img" />
                  </div>

                  <div className="tutorial-content">

                    <div className="tutorial-step tutorial-text-box">

                      <h3>{t("tutorial_create_geofence_step5_h3")}</h3>
                      <p>{t("tutorial_create_geofence_step5_p")} </p>
                    </div>
                  </div>

                  <div className="tutorial-img-container">
                    <img src={Image5} alt="icon" className="img-fluid tutorial-img" />
                  </div>

                  <div className="tutorial-content">

                    <div className="tutorial-step tutorial-text-box">

                      <h3>{t("tutorial_create_geofence_step6_h3")}</h3>
                      <p>{t("tutorial_create_geofence_step6_p")} </p>
                    </div>
                  </div>

                  <div className="tutorial-img-container">
                    <img src={Image6} alt="icon" className="img-fluid tutorial-img" />
                  </div>

                  <div className="tutorial-content">

                    <div className="tutorial-step tutorial-text-box">

                      <h3>{t("tutorial_create_geofence_step7_h3")}</h3>
                      <p>{t("tutorial_create_geofence_step7_p")}  </p>
                    </div>
                  </div>

                  <div className="tutorial-img-container">
                    <img src={Image7} alt="icon" className="img-fluid tutorial-img" />
                  </div>

                  <div className="tutorial-content">

                    <div className="tutorial-step tutorial-text-box">

                      <h3>{t("tutorial_create_geofence_step8_h3")}</h3>
                      <p>{t("tutorial_create_geofence_step8_p")}   </p>
                    </div>
                  </div>

                  <div className="tutorial-img-container">
                    <img src={Image8} alt="icon" className="img-fluid tutorial-img" />
                  </div>

                  <div className="tutorial-content">

                    <div className="tutorial-step tutorial-text-box">

                      <h3>{t("tutorial_create_geofence_finished_h3")}</h3>
                      <p> {t("tutorial_create_geofence_finished_p")}  <a href="mailto:support@tetheredgps.com">support@tetheredgps.com</a> </p>
                    </div>
                  </div>

                  <div className="tutorial-content">

                    <div className="tutorial-step tutorial-text-box">

                      <h3>{t("tutorial_create_geofence_next_h3")}</h3>
                      <p> {t("tutorial_create_geofence_next_p_part1")}  <a href="/tutorials-create-a-contact">{t("tutorial_create_geofence_next_p_part2")}</a> </p>
                    </div>
                  </div>

                </div>
              </div>
            </div>
          </section>

        </main> {/* <!-- End #main --> */}

        {/* <!-- ======= JS Files Import ======= --> 
        <a href="#" className="back-to-top d-flex align-items-center justify-content-center"><i className="bi bi-arrow-up-short"></i></a> */}

        {/* <!-- Vendor JS Files --> */}
        <script src="../vendor/aos/aos.js"></script>
        <script src="../vendor/bootstrap/js/bootstrap.bundle.min.js"></script>
        <script src="../vendor/swiper/swiper-bundle.min.js"></script>
        <script src="../vendor/php-email-form/validate.js"></script>

        {/* <!-- Template Main JS File --> */}
        <script src="../scripts/main.js"></script>
        <script src="../scripts/accordion.js"></script>

    </div>
  )
}
