import {Link } from 'react-router-dom'
import './TutorialNavbar.css'

import { useTranslation } from "react-i18next";

export default function TutorialNavbar() {
  const { t } = useTranslation();
  return (
    <div className="tutorial-navbar" >
        <ul>
            <li><Link to="/tutorials-create-a-safety-zone">{t("tutorial_navbar_create_zone")}</Link></li>
            <li><Link to="/tutorials-create-a-contact">{t("tutorial_navbar_create_contact")}</Link></li>
            <li><Link to="/tutorials-add-a-document">{t("tutorial_navbar_add_document")}</Link></li>
            <li><Link to="/tutorials-activate-panic-button">{t("tutorial_navbar_activate_panic")}</Link></li>
            <li><Link to="/tutorials-respond-to-alert">{t("tutorial_navbar_respond_to_alert")}</Link></li>
            <li><Link to="/tutorials-how-to-hide-the-app">{t("tutorial_navbar_hide_app")}</Link></li>  
            <li><Link to="/tutorials-biometric-login">{t("tutorial_navbar_biometric_login")}</Link></li>  
            <li><Link to="/tutorials-start-pause-monitoring">{t("tutorial_navbar_start_pause_monitoring")}</Link></li> 
            <li><Link to="/tutorials-contact-agent">{t("tutorial_navbar_contact_agent")}</Link></li>
            <li><Link to="/tutorials-membership-setup">{t("tutorial_navbar_membership_setup")}</Link></li>

        </ul>
    </div>
  )
}