import React from 'react';
// import {JSEventListener} from './jsEventListener.js'

export const HeaderScroll = () => {

  React.useEffect(() => {
    window.addEventListener('scroll', headerScrolled);
  
    // cleanup this component
    return () => {
      window.removeEventListener('scroll', headerScrolled);
    };
  }, []);

  const headerScrolled = (event) => {
    // console.log('page scrolled');

  /**
     * Easy selector helper function
     */

 const select = (el, all = false) => {
    el = el.trim()
    if (all) {
      return [...document.querySelectorAll(el)]
    } else {
      return document.querySelector(el)
    }
  }

  /**
   * Easy on scroll event listener 
   */
   const onscroll = (el, listener) => {
    el.addEventListener('scroll', listener)
  }
  
    let selectHeader = select('#header')
      if (selectHeader) {
        const headerScrolled = () => {
          
          if (window.scrollY > 30) {
            selectHeader.classList.add('header-scrolled')
          } else {
            selectHeader.classList.remove('header-scrolled')
          }
        }
        window.addEventListener('load', headerScrolled)
        onscroll(document, headerScrolled)
      }
  };
  
}