export default function SocialLinks() {

    return (

    <span className="center">
        <a href="https://www.linkedin.com/company/tethered-gps/" target="_blank" rel="noopener noreferrer"><span className="bi bi-linkedin"></span></a>
        <a href="https://www.facebook.com/tetheredgps1/" target="_blank" rel="noopener noreferrer"><span className="bi bi-facebook"></span></a>
        <a href="https://www.instagram.com/tetheredgps1/" target="_blank" rel="noopener noreferrer"><span className="bi bi-instagram"></span></a>
        <a href="https://www.youtube.com/channel/UCPGb-_bdOkHvmOCBIkB-RwQ" target="_blank" rel="noopener noreferrer"><span className="bi bi-youtube"></span></a>
        <a href="https://www.tiktok.com/@tetheredgps" target="_blank" rel="noopener noreferrer"><span className="bi bi-tiktok"></span></a>
    </span>

    )
}