import i18next from "i18next";

import { initReactI18next } from "react-i18next";
import LanguageDetector from "i18next-browser-languagedetector";
import HttpApi from "i18next-http-backend";


i18next

  .use(initReactI18next)
  .use(LanguageDetector)
  .use(HttpApi)
  .init({

    // resources,

    // lng: "en",
    fallbackLng: "en",
    debug: true,

    interpolation: {

      escapeValue: false,

    },

  });

export default i18next;