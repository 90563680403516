import TutorialNavbar from '../../components/TutorialNavbar.js'

// styles
import './Tutorials.css'

// images import 
import Image1 from '../../assets/img/tutorial-screenshots/respond-to-alert/step1-login-screen.png'
import Biometric2 from '../../assets/img/tutorial-screenshots/activate-biometric/img-2-biometric-phone-removebg.png'
import Biometric3 from '../../assets/img/tutorial-screenshots/activate-biometric/img-3-biometric-phone-removebg.png'
import Biometric4 from '../../assets/img/tutorial-screenshots/activate-biometric/img-4-biometric-phone-removebg.png'
import Biometric5 from '../../assets/img/tutorial-screenshots/activate-biometric/img-5-biometric-phone-removebg.png'
import Biometric6 from '../../assets/img/tutorial-screenshots/activate-biometric/img-6-biometric-phone-removebg.png'


import { useTranslation } from "react-i18next";

export default function TutorialBiometricLogin() {

  const { t } = useTranslation();

  return (
    <div>
      {/* <!-- ======= Hero Section ======= --> */} 
      <section className="hero-section" id="hero">
       

        <div className="container">
          <div className="row align-items-center">
            <div className="col-12 hero-text-image">
              <div className="row">
                <div className="col-lg-8 text-center text-lg-start">
                  <h1 data-aos="fade-right">{t("tutorial_biometric_login_h1")} <br /> </h1>
                  <p className="mb-5" data-aos="fade-right" data-aos-delay="100">{t("tutorial_biometric_login_subhead_p")}</p>  
                </div>
              </div>
            </div>
          </div>
        </div>

      </section> {/* <!-- End Hero --> */} 

      {/* Tutorial Navbar Import */}
      <TutorialNavbar />

      <main id="main">

      {/* 
      
      <!-- ======= Tutorial Section ======= -->
    
      <!-- ======= Tutorial: How To Respond To An Alert ======= -->
      
      */} 

      <section className="section">
        <div className="container" id="respond-to-alert">
          <div className="row justify-content-center text-center mb-5" data-aos="fade">
            <div className="col-md-6 mb-5 tutorial-container">
              <h2 className="section-heading" >{t("tutorial_biometric_login_h2")}</h2>
              <div className="tutorial-content">
                <div className="tutorial-step tutorial-text-box">
                  <h3>{t("tutorial_biometric_login_purpose_h3")}</h3>
                  <p> {t("tutorial_biometric_login_purpose_p")} 
                  <br /><br />
                    <h3>{t("tutorial_biometric_login_requirement_h3")}</h3>
                    {t("tutorial_biometric_login_requirement_p")} 
                  </p>
                  <br />
                  <h3>{t("tutorial_biometric_login_step1_h3")}</h3>
                  <p>{t("tutorial_biometric_login_step1_p")}</p>
                </div>
              </div>
              <div className="tutorial-img-container">
                <img src={Image1} alt="tutorial" className="img-fluid tutorial-img" />
              </div>

              <div className="tutorial-content">
                <div className="tutorial-step tutorial-text-box">
                  <h3>{t("tutorial_biometric_login_step2_h3")} </h3>
                  <p>{t("tutorial_biometric_login_step2_p")}</p>
                </div>
              </div>

              <div className="tutorial-img-container">
                <img src={Biometric2} alt="tutorial" className="img-fluid tutorial-img" />
              </div>

              <div className="tutorial-content">
                <div className="tutorial-step tutorial-text-box">
                  <h3>{t("tutorial_biometric_login_step3_h3")} </h3>
                  <p>{t("tutorial_biometric_login_step3_p")} </p>
                </div>
              </div>

              <div className="tutorial-img-container">
                <img src={Biometric3} alt="tutorial" className="img-fluid tutorial-img" />
              </div>

              <div className="tutorial-content">
                <div className="tutorial-step tutorial-text-box">
                  <h3> {t("tutorial_biometric_login_step1b_h3")} </h3>
                  <p>{t("tutorial_biometric_login_step1b_p")}</p>
                </div>
              </div>

             

              <div className="tutorial-img-container tutorial-img-container-multiple">    
                <img src={Biometric4} alt="tutorial" className="img-fluid tutorial-img tutorial-img-multiple" />
                <img src={Biometric5} alt="tutorial" className="img-fluid tutorial-img tutorial-img-multiple" />
              </div>
                          

              <div className="tutorial-content">
                <div className="tutorial-step tutorial-text-box">
                  <h3>{t("tutorial_biometric_login_step2b_h3")}</h3>
                  <p> {t("tutorial_biometric_login_step2b_p")} </p>
                </div>
              </div>

              <div className="tutorial-img-container">
                <img src={Biometric6} alt="tutorial" className="img-fluid tutorial-img" />
              </div>

             

              <div className="tutorial-content">
                <div className="tutorial-step tutorial-text-box">
                  <h3>{t("tutorial_biometric_login_finished_h3")}</h3>
                  <p> {t("tutorial_biometric_login_finished_p")}  <a href="mailto:support@tetheredgps.com">support@tetheredgps.com</a> </p>
                </div>
              </div>


              <div className="tutorial-content">
                 <div className="tutorial-step tutorial-text-box">
                  <h3>{t("tutorial_biometric_login_next_h3")}</h3>
                  <p> {t("tutorial_biometric_login_next_p_part1")}  <a href="/tutorials-start-pause-monitoring">{t("tutorial_biometric_login_next_p_part2")}</a> </p>
                 </div>
              </div>

            </div>
          </div>
        </div>
    
      </section>

    </main> {/* <!-- End #main --> */} 

      {/* <!-- ======= JS Files Import ======= --> 
      <a href="#" className="back-to-top d-flex align-items-center justify-content-center"><i className="bi bi-arrow-up-short"></i></a> */}

      {/* <!-- Vendor JS Files --> */} 
      <script src="../vendor/aos/aos.js"></script>
      <script src="../vendor/bootstrap/js/bootstrap.bundle.min.js"></script>
      <script src="../vendor/swiper/swiper-bundle.min.js"></script>
      <script src="../vendor/php-email-form/validate.js"></script>

      {/* <!-- Template Main JS File --> */} 
      <script src="../scripts/main.js"></script>
      <script src="../scripts/accordion.js"></script>

    </div>
  )
}
 