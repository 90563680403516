// styles
import './Jobs.css'
import { useTranslation } from "react-i18next";

export default function Jobs() {

  const { t } = useTranslation();

  return (
    <div>

      {/* <!-- ======= Hero Section ======= --> */} 
      <section className="hero-section" id="hero">

        <div className="container">
          <div className="row align-items-center">
            <div className="col-12 hero-text-image">
              <div className="row">
                <div className="col-lg-8 text-center text-lg-start">
                  <h1 data-aos="fade-right">{t("jobs_h1")}  </h1>
                  <p className="mb-5" data-aos="fade-right" data-aos-delay="100">{t("jobs_p")} </p> 
                </div>
              </div>
            </div>
          </div>
        </div>

      </section>{/* <!-- End Hero --> */}

      <main id="main">

        {/* <!-- ======= Jobs Page Section ======= --> */} 
        <section className="section" id="get-started">
          <div className="container">
            <div className="row justify-content-center text-center mb-5 jobs-box">
              <div className="col-md-5 col-sm-12" style={{ textAlign: "left" }}>    
                <p className="contact-list"><span className="list-icon bi bi-chat-left-dots"><strong className='contact-subtitle'>{t("jobs_email")}</strong></span><br />
                <span className="center-text"><a href="mailto:jobs@decogps.com">jobs@decogps.com</a></span>
                </p>
              </div>
            </div>
          </div>
        </section>

      </main>{/* <!-- End #main --> */}

    </div>
  )
}