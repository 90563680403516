// styles
import './ForgotPassword.css'

import {useState} from 'react'
// import {useLogin} from '../../hooks/useLogin'
import {useForgotPassword} from '../../hooks/useForgotPassword.js'
import Logo from '../../assets/img/tethered_banner.png'
import { useTranslation } from "react-i18next";
export default function ForgotPassword() {

const { t } = useTranslation();

const [email, setEmail] = useState('')
// const [password, setPassword] = useState('')
const {forgotPassword, isPending, error} = useForgotPassword()

const handleSubmit = (e) => {
  e.preventDefault()
  document.getElementById("dvMesssage").classList.remove("SuccessMessage");
  document.getElementById("dvMesssage").classList.remove("ErrorMessage");
  forgotPassword(email)
}

  return (
    <div>  
      {/* background section */}
      <section className="background-login"> 

        <form className="auth-form" onSubmit={handleSubmit}>
    
          <h3>{t("forgot_password_send_reset_h3")}</h3>
          <br></br>
          <div id="dvMesssage" style={{ display: "none" }}></div>
          <label>
            <span>{t("forgot_password_email_label")} </span>
          <input 
            required 
            type="email" 
            onChange={(e) => setEmail(e.target.value)}
            value={email}
            /> 
          </label>
          <br></br>
            {!isPending && <button id="sendBtn" type="submit" className="form-control btn btn-primary">{t("forgot_password_reset_pw")}</button> } 
            {isPending && <button className="form-control btn btn-primary" disabled>{t("forgot_password_loading")}</button> }
            {error && <div className="error">{error}</div>}
            {/* <button onClick={forgotPasswordHandler}>Forgot Password?</button> */}
        </form>

      </section> 
    </div>
  )
}