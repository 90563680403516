import { useState } from 'react';
import './Dropdown.css'
import { useTranslation } from "react-i18next";
import { select } from '../scripts/element-selector.js'

function Dropdown () {

    const { t } = useTranslation();
    const [ display, setDisplay ] = useState ('none')

    function handleClick () {
        
        if (display == 'none' ) {
            // setDisplay ('block')
            select('#header').classList.toggle('expanded')
            select('.DropdownElement').classList.toggle('Dont-Display')

        } else {
            // setDisplay ('none')
            select('#header').classList.toggle('expanded')
            select('.DropdownElement').classList.toggle('Dont-Display')
        }
    }

    return (
        <div className="DropdownButtonContainer">
            <div className="DropdownButton" id="DropdownButton"onClick={handleClick}>
                <li><a> {t("dropdown_nav_country_selection")}  &#709; </a></li>
            </div>

            <div className='DropdownElement Dont-Display' >

                <li><a href="/latin-america">{t("dropdown_nav_country_selection_latin_america")}</a></li>
                <li><a href="/mexico">{t("dropdown_nav_country_selection_mexico")}</a></li>
                <li><a href="/nigeria">{t("dropdown_nav_country_selection_nigeria")}</a></li>
                <li><a href="/united-states">{t("dropdown_nav_country_selection_america")}</a></li>
            
            </div>
        </div>
    )
}

export {Dropdown}