import {useLogout} from '../hooks/useLogout.js'
import { useAuthContext } from '../hooks/useAuthContext.js'
import './Navbar.css'
import Logo from '../assets/img/tethered_banner-white.png'
import { useTranslation } from "react-i18next";
import { Dropdown } from './Dropdown.js';

export default function Navbar() {

  const {logout, isPending} = useLogout()
  const { user } = useAuthContext()
  const { t } = useTranslation();

  return (
    <div className="navbar fixed-top " id="header">
        <div className ="logo">
            <a href="/"><img src = {Logo} alt ="logo" /></a>
            <span>  </span>
        </div>
        
        <ul id="close-nav">
            <li><a href="/about-us">{t("navbar_menu_item_about_us")}</a></li>
            <Dropdown></Dropdown>
            <li><a href="/faq">{t("navbar_menu_item_faq")}</a></li>
            <li><a href="/tutorials-create-a-safety-zone" >{t("navbar_menu_item_tutorials")}</a></li>
            <li><a href="/solutions">{t("navbar_menu_item_solutions")}</a></li>
            <li><a href="/contact-us">{t("navbar_menu_item_contact_us")}</a></li>
            <li className="big-login-button">
              {!user && <a href="/login" >{t("navbar_menu_item_login")}</a> }
            </li>
            <li>
              {!user && <a href="/signup" className="btn-react login-button">{t("navbar_menu_item_sign_up")}</a> }
            </li>
            <li>
               {user && !isPending && <button className="btn-react login-button" onClick={logout}>{t("navbar_menu_item_log_out")}</button> }
               {user && isPending && <button  className="btn-react login-button" disabled>{t("navbar_menu_item_logging_out")}</button> }
            </li>
        </ul>

        <i className="bi bi-list mobile-nav-toggle" ></i>

    </div>
  )
}