import React, { useState } from 'react';
import { projectFirestore } from "../../firebase/config.js";
import './Contact-Us.css';
import SocialLinks from '../../components/social-links.js'
import { useTranslation } from "react-i18next";

export default function ContactUs() {

  const { t } = useTranslation();
  const [user_name, setName] = useState("");
  const [user_email, setEmail] = useState("");
  const [subject, setSubject] = useState("");
  const [message, setMessage] = useState("");
  const [opened, setOpened] = useState("");
  const [closed, setClosed] = useState("");
  const [agent, setAgent] = useState("");
  const [status, setStatus] = useState("");

  // Date Constructor:
  const current = new Date();
  const currentDate = `${current.toISOString()}`;

  const handleSubmit = (e) => {
    e.preventDefault();
    document.getElementById("dvMesssage").classList.remove("SuccessMessage");
    document.getElementById("dvMesssage").classList.remove("ErrorMessage");
    projectFirestore.collection('contact-us-msgs').add({
      name: user_name,
      email: user_email,
      subject: subject,
      message: message,

      opened: currentDate,
      closed: null,
      agent: {
        "agent": {
          "email": "",
          "name": {
            "title": "",
            "first": "",
            "middle": "",
            "last": "",
            "qualifier": "",
            "verified": false
          },
        },
      },
      status: "Open",
      comments: {
        "history" : [],
      },
      
    }).then(() => {
      document.getElementById("dvMesssage").className = "SuccessMessage";
      document.getElementById("dvMesssage").innerHTML = 'Your message as been sent. A Tethered, GPS team member will contact you within 24 hours.'
      
    }).catch((error) => {
      document.getElementById("dvMesssage").className = "ErrorMessage";
      document.getElementById("dvMesssage").innerHTML=error.message;
    });
    setName("");
    setEmail("");
    setSubject("");
    setMessage("");
    setOpened("");
    setClosed("");
    setAgent("");
    setStatus("");
  };
      
  var enableSend = function handleOnChange(value) {
    const btn = document.getElementById('sendBtn');
    btn.setAttribute('disabled', '')
    document.getElementById("dvMesssage").classList.remove("SuccessMessage");
    document.getElementById("dvMesssage").classList.remove("ErrorMessage");

    if (value != null) {
      //end able send button
      btn.removeAttribute('disabled')
    }
  }   

  return (
    <div>

      {/* <!-- ======= Hero Section ======= --> */} 
      <section className="hero-section" id="hero">
        <div className="container">
          <div className="row align-items-center">
            <div className="col-12 hero-text-image">
              <div className="row">
                <div className="col-lg-8 text-center text-lg-start">
                  <h1 data-aos="fade-right">{t("contact_us_h1_part1")} <br /> {t("contact_us_h1_part2")}<br /> {t("contact_us_h1_part3")}</h1>
                  <p className="mb-5" data-aos="fade-right" data-aos-delay="100">{t("contact_us_subheader_p")}</p>  
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>{/* <!-- End Hero --> */}

      <main id="main">

      {/* <!-- ======= Home Section ======= --> */} 
        <section className="section" id="get-started">
          <div className="container">
            <div className="row justify-content-center text-center mb-5">
              <div className="contact-form-container" data-aos="fade-up">
                <div className="">           
                  <h2 className="section-heading">{t("contact_us_h2_contact")}</h2>
                  <div className="row contact-row">
                    <div className="col-md-5 col-sm-12" style={{ textAlign: "left" }}>
                      <br></br>
                      <h3>{t("contact_us_h3_contact")}</h3>
                      <p className="contact-list"><span className="list-icon bi bi-telephone"><strong className='contact-subtitle'>{t("contact_us_phone_support_p")}</strong></span><br />
                      <span className="bold center"><h5>USA: +1-239-822-2202</h5></span>
                      <span className="bold center"><h5>Nigeria: +234-806-800-4993</h5></span>
                      </p>
                      <p className="contact-list"><span className="list-icon bi bi-chat-left-dots"><strong className='contact-subtitle'>{t("contact_us_email_support_p")}</strong></span><br />
                      <span><a className="bold center" href="mailto:support@tetheredgps.com? &subject=Contact Tethered GPS Support&body=Hi, my name is:%0A%0APlease help me with: %0A%0AMy WhatsApp is:  %0A%0A " ><h5 className="blue">support@tetheredgps.com</h5></a></span>
                      </p>
                      <div className="contact-list social"><span className="list-icon bi bi-link"><strong className='contact-subtitle'>{t("contact_us_email_follow")}</strong></span><br />
                        <SocialLinks></SocialLinks>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

      </main>{/* <!-- End #main --> */}

    </div>
  )
}