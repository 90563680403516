import TutorialNavbar from '../../components/TutorialNavbar.js'

// styles
import './Tutorials.css'

// images import 
import Image1 from '../../assets/img/tutorial-screenshots/create-a-contact/step1-login-screen.png'
import Image2 from '../../assets/img/tutorial-screenshots/create-a-contact/image2.png'
import Image3 from '../../assets/img/tutorial-screenshots/create-a-contact/image3.png'
import Image4 from '../../assets/img/tutorial-screenshots/create-a-contact/image4.png'
import Image5 from '../../assets/img/tutorial-screenshots/create-a-contact/image5.png'
import Image6 from '../../assets/img/tutorial-screenshots/create-a-contact/image6.png'

import { useTranslation } from "react-i18next";

export default function TutorialCreateContact() {

  const { t } = useTranslation();

  return (

<div>

{/* <!-- ======= Hero Section ======= --> */} 
<section className="hero-section" id="hero">

  

  <div className="container">
    <div className="row align-items-center">
      <div className="col-12 hero-text-image">
        <div className="row">
          <div className="col-lg-8 text-center text-lg-start">
            <h1 data-aos="fade-right">{t("tutorial_create_contact_h1")} <br /> </h1>
            
             <p className="mb-5" data-aos="fade-right" data-aos-delay="100">{t("tutorial_create_contact_p")}</p> 
           {/* <!--   <p data-aos="fade-right" data-aos-delay="200" data-aos-offset="-500"><a href="#get-started" className="btn btn-outline-white">Get Started</a></p> --> */} 
          </div>
         
        </div>
      </div>
    </div>
  </div>

</section> {/* <!-- End Hero -->  */} 

{/* Tutorial Navbar Import */}
<TutorialNavbar />


<main id="main">

 {/* 
 <!-- ======= Tutorial Section ======= -->

  <!-- ======= Tutorial Create a Contact ======= -->
   */} 

 <section className="section">

  <div className="container" id="create-a-contact">
    
    <div className="row justify-content-center text-center mb-5" data-aos="fade">
      <div className="col-md-6 mb-5 tutorial-container">

        <h2 className="section-heading" >{t("tutorial_create_contact_h2")}</h2>

        <div className="tutorial-content">
         
          <div className="tutorial-step tutorial-text-box">
            
            <h3>{t("tutorial_create_contact_purpose_h3")}</h3>
            <p>{t("tutorial_create_contact_purpose_p_part1")} 
              <br /><br/>
              {t("tutorial_create_contact_purpose_p_part2")} 
              <br /><br />
              <h3>{t("tutorial_create_contact_requirement_h3")}</h3>
              {t("tutorial_create_contact_requirement_p")} 
              </p>

            <br />
            <h3>{t("tutorial_create_contact_step1_h3")}</h3>
            <p>{t("tutorial_create_contact_step1_p")}</p>
          </div>
        </div>

        <div className="tutorial-img-container">
        <img src={Image1} alt="tutorial" className="img-fluid tutorial-img" />
      </div>

        <div className="tutorial-content">

          <div className="tutorial-step tutorial-text-box">
            
            <h3>{t("tutorial_create_contact_step2_h3")} </h3>
            <p>{t("tutorial_create_contact_step2_p")} </p>
          </div>
        </div>

        <div className="tutorial-img-container">
        <img src={Image2} alt="tutorial" className="img-fluid tutorial-img" />
      </div>

        <div className="tutorial-content">

          <div className="tutorial-step tutorial-text-box">
            
            <h3>{t("tutorial_create_contact_step3_h3")} </h3>
            <p>{t("tutorial_create_contact_step3_p")} </p>
          </div>
        </div>

        <div className="tutorial-img-container">

        <img src={Image3} alt="tutorial" className="img-fluid tutorial-img" />
      </div>

        <div className="tutorial-content">
          
          <div className="tutorial-step tutorial-text-box">
            
            <h3> {t("tutorial_create_contact_step4_h3")} </h3>
            <p>{t("tutorial_create_contact_step4_p")} </p>
          </div>
        </div>

        <div className="tutorial-img-container">
        <img src={Image4} alt="tutorial" className="img-fluid tutorial-img" />
      </div>

        <div className="tutorial-content">

          <div className="tutorial-step tutorial-text-box">
            
            <h3>{t("tutorial_create_contact_step5_h3")}</h3>
            <p>{t("tutorial_create_contact_step5_p")}  </p>
          </div>
        </div>

        <div className="tutorial-img-container">
        <img src={Image5} alt="tutorial" className="img-fluid tutorial-img" />
      </div>

        <div className="tutorial-content">

          <div className="tutorial-step tutorial-text-box">
            
            <h3>{t("tutorial_create_contact_step6_h3")}</h3>
            <p>{t("tutorial_create_contact_step6_p")} </p>
          </div>
        </div>

        <div className="tutorial-img-container">
        <img src={Image6} alt="tutorial" className="img-fluid tutorial-img" />
      </div>

      <div className="tutorial-content">

        <div className="tutorial-step tutorial-text-box">
          
          <h3>{t("tutorial_create_contact_finished_h3")}</h3>
          <p> {t("tutorial_create_contact_finished_p")}  <a href="mailto:support@tetheredgps.com">support@tetheredgps.com</a> </p>
        </div>
      </div>

	<div className="tutorial-content">
    
          <div className="tutorial-step tutorial-text-box">
            
            <h3>{t("tutorial_create_contact_next_h3")}</h3>
            <p> {t("tutorial_create_contact_next_p_part1")}  <a href="/tutorials-add-a-document">{t("tutorial_create_contact_next_p_part2")}</a> </p>
          </div>
        </div>
      </div>
    </div>
    </div>
</section>

</main> {/* <!-- End #main --> */} 

{/* <!-- ======= JS Files Import ======= --> 
<a href="#" className="back-to-top d-flex align-items-center justify-content-center"><i className="bi bi-arrow-up-short"></i></a> */}

{/* <!-- Vendor JS Files --> */} 
<script src="../vendor/aos/aos.js"></script>
<script src="../vendor/bootstrap/js/bootstrap.bundle.min.js"></script>
<script src="../vendor/swiper/swiper-bundle.min.js"></script>
<script src="../vendor/php-email-form/validate.js"></script>

{/* <!-- Template Main JS File --> */} 
<script src="../scripts/main.js"></script>
<script src="../scripts/accordion.js"></script>

    </div>
  )
}
 