// styles
import './Latin-America.css'

// images import 
import {
  HeaderPhone2,
  GooglePlayBadge,
  AppleAppBadge,
  FlaticonCustomerReview,
  TetheredPhoneAustinPreview,
  FlaticonAlert,
  FlaticonMap,
  FlaticonAgents,
  LATAM1,
  LATAM2,
  LATAM3,
  LATAM4,
  LATAM5,
  LATAM6,
} from '../../headers/photos.js'

import React from "react";
import { useTranslation } from "react-i18next";

export default function LatinAmerica() {

  const { t } = useTranslation();
  
  return (

    <div>

      {/* <!-- ======= Hero Section ======= --> */}
      <section className="hero-section hero-section-homepage" id="hero">

        <div className="container">
          <div className="row align-items-center">
            <div className="col-12 hero-text-image">
              <div className="row">
                <div className="col-lg-8 text-center text-lg-start">
                  <h1 data-aos="fade-right">
                    {t("latin-america_h1_part1")} <br />{t("latin-america_h1_part2")}</h1>
                  <p className="mb-5" data-aos="fade-right" data-aos-delay="100">  
                    {t("latin-america_subhead_p")} </p>
                  <div className="badges-container">
                    <div className="badges-container-flex">
                      <div className="google-badge-container">
                        <a href="https://play.app.goo.gl/?link=https://play.google.com/store/apps/details?id=com.tetheredgps.tethered" target="_blank" rel="noopener noreferrer"> <img src={GooglePlayBadge} className="google-play-badge" alt="app-badge" /> </a>
                      </div>
                      <div className="apple-badge-container">
                        <a href="https://apps.apple.com/us/app/tethered-gps/id6450391042" > <img src={AppleAppBadge} className="appple-app-badge" alt="app-badge" /> </a> 
                      </div>
                    </div>
                  </div>
                  <br />
                </div>
                
                <div className="col-lg-4 iphone-wrap">
                  <img src={TetheredPhoneAustinPreview} alt="phone" className="phone-1" data-aos="fade-right" data-aos-delay="200" />
                  <img src={HeaderPhone2} alt="phone" className="phone-2" data-aos="fade-right" />
                </div> 
              </div>
            </div>
          </div>
        </div>

      </section>{/* <!-- End Hero --> */}

      <main id="main">

      {/* <!-- ======= Home Section ======= --> */}
        
        <section className="section">
          <div className="container">

        

            <div className="row justify-content-center text-center mb-5">
              <div  data-aos="fade-up">
                <h2 className="section-heading home-page-h2">{t("latin-america_personal_security_h2")} </h2>
              </div>
            </div>

            <div className="row">

              <div className="home-safety-container">
              <div className="home-safety"> 

              <div className="home-safety-box" data-aos="fade-up" data-aos-delay="">
                <div className="feature-1 text-center">

                  <div className="home-safety-box-inner">

                  <div className="wrap-icon icon-1">

                    <img className="graphic-icon" src={FlaticonAlert} alt="icon"/>
                  </div>
                  <h3 className="mb-3">{t("latin-america_personal_security_SOS_h3")}</h3>
                  {/* <p>Take steps to improve your personal safety and security, as you move about your day, by using the latest in security monitoring technology.</p> */}
                </div>

              </div>

              </div>

              <div className="home-safety-box" data-aos="fade-up" data-aos-delay="100">
                <div className="feature-1 text-center">
                  
                  <div className="home-safety-box-inner">
                  
                  <div className="wrap-icon icon-1">
                    <img className="graphic-icon" src={FlaticonMap} alt="icon" />
                  </div>
                  <h3 className="mb-3">{t("latin-america_personal_security_custom_h3")}</h3>
                  {/* <p>Gain access to security and safety travel information before you make your travel arrangements.</p> */}
                </div>
              </div>
              </div>

              <div className="home-safety-box" data-aos="fade-up" data-aos-delay="200">
                <div className="feature-1 text-center">

                  <div className="home-safety-box-inner">

                  <div className="wrap-icon icon-1">
                    <img className="graphic-icon" src={FlaticonCustomerReview} alt="icon" />
                  </div>
                  <h3 className="mb-3">{t("latin-america_personal_security_emergency_h3")}</h3>
                  {/* <p>Meet your duty of care by properly protecting your employees, when they travel for your organization or government agency... You need to know they are safe 24/7.</p> */}
                </div>
              </div>
              </div>

              <div className="home-safety-box" data-aos="fade-up" data-aos-delay="">
                <div className="feature-1 text-center">

                <div className="home-safety-box-inner">

                  <div className="wrap-icon icon-1">
                    <img className="graphic-icon" src={FlaticonAgents} alt="icon" />
                  </div>
                  <h3 className="mb-3">{t("latin-america_personal_security_24_h3")}</h3>
                  {/* <p>Gain access to 21st century technology and information to have security on your side and reduce the odds of paying costly ransoms.</p> */}
                </div>
                </div>
              </div>
              </div>
            </div>
            </div>
          </div>
        </section>



        {/* <!-- Parallax Section --> */} 
      <section className="banner-latam-1">
      </section>


        <section className="section">
          <div className="container">
            <div className="row align-items-center home-section">
              <h2 className="section-heading" id="stats-visibility-element">{t("latin-america_power_h2")}</h2>
                <div className="me-auto home-power home-power-top " id="home-stats">
                
              </div>
            </div>
          </div>
        </section>

        <section className="section">
          <div className="container">
            <div className="row align-items-center home-section">
              <div className="col-md-4 ms-auto order-2 home-power">
                <h3 className="mb-4">{t("latin-america_power_why_h3")}</h3>
                <p className="p-mod">{t("latin-america_power_why_p_part1")} <br /> <br />

                  {t("latin-america_power_why_p_part2")} <br /> <br />
                  
                </p>
                  <br />
              </div>
              <div className="col-md-6 home-images" data-aos="fade-right">
                <img src={LATAM1} alt="icon" className="img-fluid img-home" />
              </div>
            </div>
          </div>
        </section>

        <section className="section">
          <div className="container">
            <div className="row align-items-center home-section">
              <div className="col-md-4 me-auto home-power">
                <h3 className="mb-4">{t("latin-america_power_mexico_h3")}</h3>
                <p className="p-mod"> {t("latin-america_power_mexico_p_part1")}  
                  
                  <br /> <br />
                  {t("latin-america_power_mexico_p_part2")}
                  </p>
                  <br />
              </div>
              <div className="col-md-6 home-images" data-aos="fade-left">
                <img src={LATAM2} alt="icon" className="img-fluid img-home mobile-off" />
              </div>
            </div>
          </div>
        </section>

        <section className="section">
          <div className="container">
            <div className="row align-items-center home-section">

              <div className="col-md-4 ms-auto order-2 home-power">
                <h3 className="mb-4">{t("latin-america_power_enterprise_h3")}</h3>
                {/* <h5>{t("latin-america_power_enterprise_h5")}</h5> */}
                <br></br>
                <p className="p-mod">{t("latin-america_power_enterprise_p_part1")} <br /> <br />

                  {t("latin-america_power_enterprise_p_part2")} <br /> <br />
                  
                  </p>
                  <br />
              
              </div>
              <div className="col-md-6 home-images" data-aos="fade-right">
                <img src={LATAM3} alt="icon" className="img-fluid img-home" />
              </div>

            </div>
          </div>
        </section>



      

        <section className="section">
          <div className="container">
            <div className="row align-items-center home-section">
              <div className="col-md-4 me-auto home-power">
                <h3 className="mb-4">{t("latin-america_power_challenges_h3")}</h3>
                <p className="p-mod"> {t("latin-america_power_challenges_p_part1")}  
                  
                  <br /> <br />
                  {t("latin-america_power_challenges_p_part2")}
                  </p>
                  <br />
              </div>
              <div className="col-md-6 home-images" data-aos="fade-left">
                <img src={LATAM4} alt="icon" className="img-fluid img-home mobile-off" />
              </div>
            </div>
          </div>
        </section>

        <section className="section">
          <div className="container">
            <div className="row align-items-center home-section">

              <div className="col-md-4 ms-auto order-2 home-power">
                <h3 className="mb-4">{t("latin-america_power_cta_h3")}</h3>
                {/* <h5>{t("latin-america_power_cta_h5")}</h5> */}
                <br></br>
                <p className="p-mod">{t("latin-america_power_cta_p_part1")} <br /> <br />

                  {t("latin-america_power_cta_p_part2")} <br /> <br />
                  
                </p>
                  <br />
              
              </div>
              <div className="col-md-6 home-images" data-aos="fade-right">
                <img src={LATAM5} alt="icon" className="img-fluid img-home" />
              </div>

            </div>
          </div>
        </section>

        <section className="section">
          <div className="container">
            <div className="row align-items-center home-section">
              <div className="col-md-4 me-auto home-power">
                <h3 className="mb-4">{t("latin-america_power_solution_h3")}</h3>
                <p className="p-mod"> {t("latin-america_power_solution_p_part1")}  
                  
                  <br /> <br />
                  {t("latin-america_power_solution_p_part2")}

                  <br /> <br />
                  {t("latin-america_power_solution_p_part3")}

                  <br /> <br />
                  {t("latin-america_power_solution_p_part4")}
                  </p>
                  <br />

                <div className="badges-container">

                  <div className="badges-container-flex badges-container-flex-tablet">

                    <div className="google-badge-container">
                      <a href="https://play.app.goo.gl/?link=https://play.google.com/store/apps/details?id=com.tetheredgps.tethered" target="_blank" rel="noopener noreferrer"> <img src={GooglePlayBadge} className="google-play-badge" alt="app-badge" /> </a>
                    </div>
                      
                    <div className="apple-badge-container">
                      <a href="https://apps.apple.com/us/app/tethered-gps/id6450391042" > <img src={AppleAppBadge} className="appple-app-badge" alt="app-badge" /> </a> 
                    </div>

                  </div>             

                </div>

              </div>
              <div className="col-md-6 home-images" data-aos="fade-left">
                <img src={LATAM6} alt="icon" className="img-fluid img-home mobile-off" />
              </div>
            </div>
          </div>
        </section>
        

        
      </main>
      {/* <!-- End #main --> */}

    {/* <!-- Template Main JS File --> */}
    <script src="scripts/stats-counter.js"></script>

    {/* <!-- Newsfeed/Testimonial Slider JS File --> */}
    <script src="scripts/testimonials.js"></script>

    </div>
    
  )
}
 