import TutorialNavbar from '../../components/TutorialNavbar.js'

// styles
import './Tutorials.css'

// images import 
import Image1 from '../../assets/img/tutorial-screenshots/respond-to-alert/step1-login-screen.png'
import Image2 from '../../assets/img/tutorial-screenshots/how-start-pause-monitoring/img-2-monitoring-removebg.png'
import Image3 from '../../assets/img/tutorial-screenshots/how-start-pause-monitoring/img-3-monitoring-removebg.png'
import Image4 from '../../assets/img/tutorial-screenshots/how-start-pause-monitoring/img-4-monitoring-removebg.png'
import Image5 from '../../assets/img/tutorial-screenshots/how-start-pause-monitoring/img-5-monitoring-removebg.png'
import Image7 from '../../assets/img/tutorial-screenshots/how-start-pause-monitoring/img-7-monitoring-removebg.png'
import Image8 from '../../assets/img/tutorial-screenshots/how-start-pause-monitoring/img-8-monitoring-removebg.png'
import Image9 from '../../assets/img/tutorial-screenshots/how-start-pause-monitoring/img-9-monitoring-phone-removebg.png'
import Image10 from '../../assets/img/tutorial-screenshots/how-start-pause-monitoring/img-10-monitoring-phone-removebg.png'

import { useTranslation } from "react-i18next";

export default function TutorialStartPauseMonitoring() {

  const { t } = useTranslation();

  return (
    <div>
      {/* <!-- ======= Hero Section ======= --> */} 
      <section className="hero-section" id="hero">
        <div className="container">
          <div className="row align-items-center">
            <div className="col-12 hero-text-image">
              <div className="row">
                <div className="col-lg-8 text-center text-lg-start">
                  <h1 data-aos="fade-right">{t("tutorial_start_pause_monitoring_h1")} <br /> </h1>
                  <p className="mb-5" data-aos="fade-right" data-aos-delay="100">{t("tutorial_start_pause_monitoring_subhead_p")}</p>  
                </div>
              </div>
            </div>
          </div>
        </div>
      </section> {/* <!-- End Hero --> */} 


      {/* Tutorial Navbar Import */}
      <TutorialNavbar />

      <main id="main">

      <section className="section">
        <div className="container" id="respond-to-alert">
          <div className="row justify-content-center text-center mb-5" data-aos="fade">
            <div className="col-md-6 mb-5 tutorial-container">
              <h2 className="section-heading" >{t("tutorial_start_pause_monitoring_h2")}</h2>
              <div className="tutorial-content">
                <div className="tutorial-step tutorial-text-box">
                  <h3>{t("tutorial_start_pause_monitoring_purpose_h3")}</h3>
                  <p> {t("tutorial_start_pause_monitoring_purpose_p")} 
                  <br /><br />
                  <h3>{t("tutorial_start_pause_monitoring_requirement_h3")}</h3>
                  {t("tutorial_start_pause_monitoring_requirement_p")}                   
                  </p>
                  <br />
                  <h3>{t("tutorial_start_pause_monitoring_step1_h3")}</h3>
                  <p>{t("tutorial_start_pause_monitoring_step1_p")}</p>
                </div>
              </div>

              <div className="tutorial-img-container">
                <img src={Image1} alt="tutorial" className="img-fluid tutorial-img" />
              </div>

              <div className="tutorial-content">
                <div className="tutorial-step tutorial-text-box">
                  <h3>{t("tutorial_start_pause_monitoring_step2_h3")} </h3>
                  <p>{t("tutorial_start_pause_monitoring_step2_p")}</p>
                </div>
              </div>

              <div className="tutorial-img-container tutorial-img-container-multiple">
                 <img src={Image2} alt="tutorial" className="img-fluid tutorial-img tutorial-img-multiple" />
                 <img src={Image3} alt="tutorial" className="img-fluid tutorial-img tutorial-img-multiple" />
              </div>

              <div className="tutorial-content">
                <div className="tutorial-step tutorial-text-box">
                  <h3>{t("tutorial_start_pause_monitoring_step3_h3")} </h3>
                  <p>{t("tutorial_start_pause_monitoring_step3_p")} </p>
                </div>
              </div>

              <div className="tutorial-img-container tutorial-img-container-multiple">       
                <img src={Image4} alt="tutorial" className="img-fluid tutorial-img tutorial-img-multiple" />
                <img src={Image5} alt="tutorial" className="img-fluid tutorial-img tutorial-img-multiple" />
              </div>

              <div className="tutorial-content">
                <div className="tutorial-step tutorial-text-box">
                  <h3> {t("tutorial_start_pause_monitoring_step1b_h3")} </h3>
                  <p>{t("tutorial_start_pause_monitoring_step1b_p")}</p>
                </div>
              </div>

              <div className="tutorial-img-container tutorial-img-container-multiple">       
                <img src={Image7} alt="tutorial" className="img-fluid tutorial-img tutorial-img-multiple" />
                <img src={Image8} alt="tutorial" className="img-fluid tutorial-img tutorial-img-multiple" />
              </div>

              <div className="tutorial-step tutorial-text-box">
                  <h3>{t("tutorial_start_pause_monitoring_travel_mode")}</h3>                
                  <h3>{t("tutorial_start_pause_monitoring_travel_mode_2")}</h3>
                  <p>{t("tutorial_start_pause_monitoring_travel_mode_3")}</p>
              </div>


              <div className="tutorial-img-container tutorial-img-container-multiple">       
                <img src={Image9} alt="tutorial" className="img-fluid tutorial-img tutorial-img-multiple" />
                <img src={Image10} alt="tutorial" className="img-fluid tutorial-img tutorial-img-multiple" />
              </div>

              <div className="tutorial-content">
                <div className="tutorial-step tutorial-text-box">
                  <h3>{t("tutorial_start_pause_monitoring_finished_h3")}</h3>
                  <p> {t("tutorial_start_pause_monitoring_finished_p")}  <a href="mailto:support@tetheredgps.com">support@tetheredgps.com</a> </p>
                </div>
              </div>

              <div className="tutorial-content">
                 <div className="tutorial-step tutorial-text-box">
                  <h3>{t("tutorial_start_pause_monitoring_next_h3")}</h3>
                  <p> {t("tutorial_start_pause_monitoring_next_p_part1")}  <a href="/tutorials-contact-agent">{t("tutorial_start_pause_monitoring_next_p_part2")}</a> </p>
                 </div>
              </div>

            </div>
          </div>
        </div>
      </section>

    </main> {/* <!-- End #main --> */} 

      {/* <!-- Vendor JS Files --> */} 
      <script src="../vendor/aos/aos.js"></script>
      <script src="../vendor/bootstrap/js/bootstrap.bundle.min.js"></script>
      <script src="../vendor/swiper/swiper-bundle.min.js"></script>
      <script src="../vendor/php-email-form/validate.js"></script>

      {/* <!-- Template Main JS File --> */} 
      <script src="../scripts/main.js"></script>

    </div>
  )
}
 