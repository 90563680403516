import { useState, useEffect } from 'react'
import { projectAuth } from '../firebase/config.js'
import { useNavigate  } from 'react-router-dom';

export const useForgotPassword = () => {
  const [isCancelled, setIsCancelled] = useState(false)
  const [error, setError] = useState(null)
  const [isPending, setIsPending] = useState(false)
  const history = useNavigate();
 
  const forgotPassword = async (email) => {
    setError(null)
    setIsPending(true)
  
    try {
      // login using email / password
      const res = await projectAuth.sendPasswordResetEmail(email).then(() => {
        document.getElementById("dvMesssage").className = "SuccessMessage";
        document.getElementById("dvMesssage").innerHTML = 'Success! Your password reset email has been sent.'
      })

      if (!isCancelled) {
        setIsPending(false)
        setError(null)
      }

    } 
    catch(err) {
      if (!isCancelled) {
        setError(err.message)
        setIsPending(false)
      }
    }
  }

  useEffect(() => {
    return () => setIsCancelled(true)
  }, [])

  return { forgotPassword, isPending, error }
}