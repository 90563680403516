// styles
import './About-Us.css'

// images import 
import {
  TeamJeff,
  TeamSteve,
  TeamGabriel,
  TeamNik,
  TeamGreg,
  TeamJimena,
  TeamLaura,
  TeamArthur
} from '../../headers/photos.js'

import { useTranslation } from "react-i18next";

export default function AboutUS() {
  const { t } = useTranslation();
  return (
    <div>

      {/* <!-- ======= Hero Section ======= --> */} 
      <section className="hero-section" id="hero">
        <div className="container">
          <div className="row align-items-center">
            <div className="col-12 hero-text-image">
              <div className="row">
                <div className="col-lg-8 text-center text-lg-start">
                  <h1 data-aos="fade-right">{t("about_us_h1_part1")}<br />{t("about_us_h1_part3")} </h1>
                  <p className="mb-5" data-aos="fade-right" data-aos-delay="100">{t("about_us_subhead_p")}</p>  
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>{/* <!-- End Hero --> */}

      <main id="main">

      {/* <!-- The Mission Section --> */} 
        <section className="section" id="get-started">
          <div className="container">
            <div className="row justify-content-center text-center mb-5">
              <div className="col-md-5" data-aos="fade-up">
                <h2 className="section-heading">{t("about_us_mission_h2")}</h2>
              </div>
            </div>
            <div className="row mission-container">
              <div className="mission-box" data-aos="fade-up" data-aos-delay="">
                <div className="feature-1 text-center mission-text">
                  <h2 className="mission-statement">{t("about_us_mission_statement_h2")}</h2>
                </div>
              </div>
            </div>
          </div>
        </section>

        {/* <!-- The Team Section --> */} 
        <section className="section">
          <div className="container">
            <div className="row justify-content-center text-center mb-5">
              <div className="col-md-5" data-aos="fade-up">
                <h2 className="section-heading">{t("about_us_team_h2")}</h2>
              </div>
            </div>

            <div className="row">
              <div className="col-md-4" data-aos="fade-up" data-aos-delay="">
                <div className="feature-1 text-center">
                  <div className="team-photo">
                    <img className="team-photo-img" src={TeamJeff} alt="team"/>
                  </div>
                  <h3 className="mb-3">{t("about_us_team_jeff_h3")}</h3>
                  <p>{t("about_us_team_jeff_p_part1")} <br /> {t("about_us_team_jeff_p_part2")}</p>
                </div>
              </div>

              <div className="col-md-4" data-aos="fade-up" data-aos-delay="">
                <div className="feature-1 text-center">
                  <div className="team-photo">
                    <img className="team-photo-img" src={TeamSteve} alt="team"/>
                  </div>
                  <h3 className="mb-3">{t("about_us_team_steve_h3")}</h3>
                  <p>{t("about_us_team_steve_p_part1")} <br /> {t("about_us_team_steve_p_part2")}.</p>
                </div>
              </div>

              <div className="col-md-4" data-aos="fade-up" data-aos-delay="">
                <div className="feature-1 text-center">
                  <div className="team-photo">
                    <img className="team-photo-img" src={TeamGabriel} alt="team"/>
                  </div>
                  <h3 className="mb-3">{t("about_us_team_gabriel_h3")}</h3>
                  <p>{t("about_us_team_gabriel_p_part1")} <br /> {t("about_us_team_gabriel_p_part2")}</p>
                </div>
              </div>

              <div className="col-md-4" data-aos="fade-up" data-aos-delay="">
                <div className="feature-1 text-center">
                  <div className="team-photo">
                    <img className="team-photo-img" src={TeamNik} alt="team"/>
                  </div>
                  <h3 className="mb-3">{t("about_us_team_nik_h3")}</h3>
                  <p>{t("about_us_team_nik_p_part1")}</p>
                </div>
              </div>

              <div className="col-md-4" data-aos="fade-up" data-aos-delay="">
                <div className="feature-1 text-center">
                  <div className="team-photo">
                    <img className="team-photo-img" src={TeamGreg} alt="team"/>
                  </div>
                  <h3 className="mb-3">{t("about_us_team_greg_h3")}</h3>
                  <p>{t("about_us_team_greg_p_part1")}</p>
                </div>
              </div>

              <div className="col-md-4" data-aos="fade-up" data-aos-delay="">
                <div className="feature-1 text-center">
                  <div className="team-photo">
                    <img className="team-photo-img" src={TeamJimena} alt="team"/>
                  </div>
                  <h3 className="mb-3">{t("about_us_team_jimena_h3")}</h3>
                  <p>{t("about_us_team_jimena_p_part1")}</p>
                </div>
              </div>

              <div className="col-md-4" data-aos="fade-up" data-aos-delay="">
                <div className="feature-1 text-center">
                  <div className="team-photo">
                    <img className="team-photo-img" src={TeamLaura} alt="team"/>
                  </div>
                  <h3 className="mb-3">{t("about_us_team_laura_h3")}</h3>
                  <p>{t("about_us_team_laura_p_part1")}</p>
                </div>
              </div>

              <div className="col-md-4" data-aos="fade-up" data-aos-delay="">
                <div className="feature-1 text-center">
                  <div className="team-photo">
                    <img className="team-photo-img" src={TeamArthur} alt="team"/>
                  </div>
                  <h3 className="mb-3">{t("about_us_team_arthur_h3")}</h3>
                  <p>{t("about_us_team_arthur_p_part1")}</p>
                </div>
              </div>
            </div>
          </div>
        </section>

      </main>{/* <!-- End #main --> */} 

    </div>
  )
}