// styles
import './Nigeria.css'


// images import 
import Phone1 from '../../assets/img/tethered-phone-img-5-compress2.webp'
import Phone2 from '../../assets/img/tethered-phone-img-1-compress2.webp'
import FlaticonWorkTeam from '../../assets/img/icons/work-team-flaticon.png'
import FlaticonCreativeProcess from '../../assets/img/icons/creative-process-flaticon.png'
import FlaticonCustomerReview from '../../assets/img/icons/customer-reviews-flaticon.png'
import FlaticonPadlockKey from '../../assets/img/icons/padlock-key-flaticon.png'
import NewsfeedPremiumTimesKidnapping from '../../assets/img/newsfeed/premium-times-kidnapping-snapshot-compress.webp'
import NewsfeedRansomPhoto from '../../assets/img/newsfeed/leadership-ransom-photo-compress.webp'
import NewsfeedSteveThumbnail from '../../assets/img/newsfeed/steve-thumnail-1-playbutton-compress.webp'
import NewsfeedArticle5 from '../../assets/img/newsfeed/newspaper-article-5-compress.webp'
import NewsfeedSteveThumbnail2 from '../../assets/img/newsfeed/steve-thumnail-3-playbutton-compress2.webp'
import NewsfeedArticle6 from '../../assets/img/newsfeed/newspaper-article-6-compress.webp'
import TetheredPhone5Preview from '../../assets/img/tethered-phone-img-5-compress2.webp'
import MotherDaughter from '../../assets/img/Mother_Daughter-2-compress.webp'
import PWCEmergingMarkets from '../../assets/img/pwc-emerging-markets-12-July-compress2.webp'
import DECOPhoto5 from '../../assets/img/nigeria-deco-office-8-compress.webp'
import GooglePlayBadge from '../../assets/img/icons/google-play-badge.png'
import AppleAppBadge from '../../assets/img/icons/Download_on_the_App_Store_Badge_US-UK_RGB_blk_092917.svg'

import React from "react";

// import {Swiper} from 'swiper/bundle';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import SwiperCore, { Autoplay, Navigation, EffectCoverflow, Pagination } from "swiper/modules";
import 'swiper/css/bundle';

// import { Swiper, SwiperSlide } from "swiper/react";
// import "swiper/swiper-bundle.min.css";
// import "swiper/swiper.min.css";

// import "./App.css";

import { useTranslation } from "react-i18next";

// SwiperCore.use([Autoplay, Navigation, EffectCoverflow, Pagination]);


export default function Nigeria() {

  const { t } = useTranslation();
  console.log('browser language is : ' + navigator.language)

  return (

<div>

{/* <!-- ======= Hero Section ======= --> */}

<section className="hero-section hero-section-homepage" id="hero">

  {/* <div className="wave" >

    <svg width="100%" height="355px" viewBox="0 0 1920 300" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" >

      <g id="Page-1" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <g id="Apple-TV" transform="translate(0.000000, -402.000000)" fill="#FFFFFF">
          <path d="M0,439.134243 C175.04074,464.89273 327.944386,477.771974 458.710937,477.771974 C654.860765,477.771974 870.645295,442.632362 1205.9828,410.192501 C1429.54114,388.565926 1667.54687,411.092417 1920,477.771974 L1920,757 L1017.15166,757 L0,757 L0,439.134243 Z" id="Path"></path>
        </g>
      </g>
    </svg>

  </div> */}

  <div className="container">
    <div className="row align-items-center">
      <div className="col-12 hero-text-image">
        <div className="row">
          <div className="col-lg-8 text-center text-lg-start">
            <h1 data-aos="fade-right">
              {t("nigeria_page_h1_part1")}  <br />{t("nigeria_page_h1_part2")}</h1>
             <p className="mb-5" data-aos="fade-right" data-aos-delay="100">  
               {t("nigeria_page_header_subhead_p")} <br></br>
               {/* {t("app_name")} */}
               </p>

               
          <div className="badges-container">



             <div className="badges-container-flex">

              <div className="google-badge-container">
               <a href="https://play.app.goo.gl/?link=https://play.google.com/store/apps/details?id=com.tetheredgps.tethered" target="_blank" rel="noopener noreferrer"> <img src={GooglePlayBadge} className="google-play-badge" alt="app-badge" /> </a>
              </div>
    
              <div className="apple-badge-container">
               <a href="https://apps.apple.com/us/app/tethered-gps/id6450391042" > <img src={AppleAppBadge} className="appple-app-badge" alt="app-badge" /> </a> 
              </div>

            </div>


          </div>
               

            {/* <p data-aos="fade-right" data-aos-delay="200" data-aos-offset="-500"><a href="/contact-us" className="btn btn-outline-white">Coming Soon...</a></p>  */}
            {/* Get The App */}
            <br />
          </div>
          
           <div className="col-lg-4 iphone-wrap">
            <img src={Phone1} alt="phone" className="phone-1" data-aos="fade-right" data-aos-delay="200" />
            <img src={Phone2} alt="phone" className="phone-2" data-aos="fade-right" />
            
          </div> 
        </div>
      </div>
    </div>
  </div>

</section>{/* <!-- End Hero --> */}

<main id="main">

{/* <!-- ======= Home Section ======= --> */}
  
  <section className="section">
    <div className="container">

   

      <div className="row justify-content-center text-center mb-5">
        <div  data-aos="fade-up">
          <h2 className="section-heading home-page-h2">{t("nigeria_page_dont_go_alone_h2")} </h2>
        </div>
      </div>

      <div className="row">

        <div className="home-safety-container">
        <div className="home-safety"> 

        <div className="home-safety-box" data-aos="fade-up" data-aos-delay="">
          <div className="feature-1 text-center">

            <div className="home-safety-box-inner">

            <div className="wrap-icon icon-1">

              <img className="graphic-icon" src={FlaticonWorkTeam} alt="icon"/>
            </div>
            <h3 className="mb-3">{t("nigeria_page_dont_go_alone_h3_improve_safety")}</h3>
            <p>{t("nigeria_page_dont_go_alone_p_improve_safety")}</p>
          </div>

        </div>

        </div>

        <div className="home-safety-box" data-aos="fade-up" data-aos-delay="100">
          <div className="feature-1 text-center">
            
            <div className="home-safety-box-inner">
            
            <div className="wrap-icon icon-1">
              <img className="graphic-icon" src={FlaticonCreativeProcess} alt="icon" />
            </div>
            <h3 className="mb-3">{t("nigeria_page_dont_go_alone_h3_know_before")}</h3>
            <p>{t("nigeria_page_dont_go_alone_p_know_before")}</p>
          </div>
        </div>
        </div>

        <div className="home-safety-box" data-aos="fade-up" data-aos-delay="200">
          <div className="feature-1 text-center">

            <div className="home-safety-box-inner">

            <div className="wrap-icon icon-1">
              <img className="graphic-icon" src={FlaticonCustomerReview} alt="icon" />
            </div>
            <h3 className="mb-3">{t("nigeria_page_dont_go_alone_h3_protect_employees")}</h3>
            <p>{t("nigeria_page_dont_go_alone_p_protect_employees")}</p>
          </div>
        </div>
        </div>

        <div className="home-safety-box" data-aos="fade-up" data-aos-delay="">
          <div className="feature-1 text-center">

          <div className="home-safety-box-inner">

            <div className="wrap-icon icon-1">
              <img className="graphic-icon" src={FlaticonPadlockKey} alt="icon" />
            </div>
            <h3 className="mb-3">{t("nigeria_page_dont_go_alone_h3_victim")}</h3>
            <p>{t("nigeria_page_dont_go_alone_p_victrim")}</p>
          </div>
          </div>
        </div>
        </div>
      </div>
      </div>
    </div>
  </section>

{/* <!-- ======= Newsfeed Section ======= --> */}

<section className="section border-top border-bottom">
  <div className="container newsfeed">
    <div className="row justify-content-center text-center mb-5">
      
        <h2 className="section-heading">{t("nigeria_page_newsfeed_h2")}</h2>
      
    </div>

    {/* <Swiper
        effect={"coverflow"}
        grabCursor={true}
        centeredSlides={true}
        slidesPerView={"auto"}
        coverflowEffect={{
          rotate: 0,
          stretch: 0,
          depth: 100,
          modifier: 1,
          slideShadows: false,
        }}
        pagination={true}
        autoplay={{ 
          delay: 3000,
        }}
        className="mySwiper"
      >
        <SwiperSlide>
          
           <div className="newsfeed-swiper-slide">
              <div className="review text-center newsfeed">
               
                <a href="https://www.premiumtimesng.com/news/headlines/548525-kidnappers-collected-n650-million-as-ransom-in-one-year-report.html" target="_blank" rel="noopener noreferrer"> <h3 className="temp-newsfeed-h3">{t("nigeria_page_newsfeed_article1_h3")}</h3> </a>
                <blockquote >
                  <p>{t("nigeria_page_newsfeed_article1_p")} </p>
                </blockquote>

                <p className="review-user newsfeed-photo-container">
                  <span className="d-block">
                   
                    <span className="text-black bold"><a href="https://www.premiumtimesng.com/news/headlines/548525-kidnappers-collected-n650-million-as-ransom-in-one-year-report.html" target="_blank" rel="noopener noreferrer">{t("nigeria_page_newsfeed_article1_url")}</a></span>
                    <br /><br />
                  </span>

                  <a href="https://www.premiumtimesng.com/news/headlines/548525-kidnappers-collected-n650-million-as-ransom-in-one-year-report.html" target="_blank" rel="noopener noreferrer"> <img src={NewsfeedPremiumTimesKidnapping} alt="icon" className="newsfeed-photo" /> </a>
                  
                </p>

              </div> 
            </div> 
        </SwiperSlide>
        <SwiperSlide>
          
         
          <div className="newsfeed-swiper-slide">
              <div className="review text-center newsfeed">
               
                <a href="https://leadership.ng/n20m-ransom-father-of-3-abducted-children-sells-car-puts-2-houses-for-sale/" target="_blank" rel="noopener noreferrer"> <h3>{t("nigeria_page_newsfeed_article2_h3")}</h3> </a>
                <blockquote >
                  <p>{t("nigeria_page_newsfeed_article2_p")} </p>
                </blockquote>

                <p className="review-user newsfeed-photo-container">
                  <span className="d-block">
                   
                    <span className="text-black bold"><a href="https://leadership.ng/n20m-ransom-father-of-3-abducted-children-sells-car-puts-2-houses-for-sale/" target="_blank" rel="noopener noreferrer">{t("nigeria_page_newsfeed_article2_url")}</a></span>
                    <br /><br />
                  </span>

                  <a href="https://leadership.ng/n20m-ransom-father-of-3-abducted-children-sells-car-puts-2-houses-for-sale/" target="_blank" rel="noopener noreferrer"> <img src={NewsfeedRansomPhoto} alt="icon" className="newsfeed-photo" /> </a>
                  
                </p>

              </div> 
            </div>  

        </SwiperSlide>
        <SwiperSlide>
          
        
                  
        <div className="newsfeed-swiper-slide">
              <div className="review text-center newsfeed">
               
                <a href="https://www.youtube.com/watch?v=_VjxxrvYaKM" target="_blank" rel="noopener noreferrer"> <h3>{t("nigeria_page_newsfeed_article3_h3")}</h3> </a>
                <blockquote >
                  <p>{t("nigeria_page_newsfeed_article3_p1")}
                    <br />
                    {t("nigeria_page_newsfeed_article3_p2")}
                  </p>
                </blockquote>

                <p className="review-user newsfeed-photo-container">

                  <span className="d-block">
                    
                    <span className="text-black bold"><a href="https://www.youtube.com/watch?v=_VjxxrvYaKM" target="_blank" rel="noopener noreferrer">{t("nigeria_page_newsfeed_article3_url")}</a></span>
                    <br /><br />
                  </span>

                  <a href="https://www.youtube.com/watch?v=_VjxxrvYaKM" target="_blank" rel="noopener noreferrer"> <img src={NewsfeedSteveThumbnail} alt="icon" className="newsfeed-photo" /> </a>
                  
                </p>

              </div>
            </div>

        </SwiperSlide>
        <SwiperSlide>
          
       
        <div className="newsfeed-swiper-slide">
              <div className="review text-center newsfeed">
               
                <a href="https://dailytrust.com/outrage-over-rising-spate-of-kidnappings" target="_blank" rel="noopener noreferrer"> <h3>{t("nigeria_page_newsfeed_article4_h3")}</h3> </a>
                <blockquote >
                  <p></p>
                </blockquote>

                <p className="review-user newsfeed-photo-container">
                  <span className="d-block">
                   
                    <span className="text-black bold"><a href="https://dailytrust.com/outrage-over-rising-spate-of-kidnappings" target="_blank" rel="noopener noreferrer">{t("nigeria_page_newsfeed_article4_url")}</a></span>
                    <br /><br />
                  </span>

                  <a href="https://dailytrust.com/outrage-over-rising-spate-of-kidnappings" target="_blank" rel="noopener noreferrer"> <img src={NewsfeedArticle5} alt="icon" className="newsfeed-photo" /> </a>
                  
                </p>

              </div>
            </div>

        </SwiperSlide>
        <SwiperSlide>
          
          
          <div className="newsfeed-swiper-slide">
              <div className="review text-center newsfeed">
               
                <a href="https://www.youtube.com/watch?v=jqiVeo8zK-w" target="_blank" rel="noopener noreferrer"> <h3>{t("nigeria_page_newsfeed_article5_h3")}</h3> </a>
                <blockquote >
                  <p>{t("nigeria_page_newsfeed_article5_p1")}
                    <br />
                    {t("nigeria_page_newsfeed_article5_p2")}
                  </p>
                </blockquote>

                <p className="review-user newsfeed-photo-container">

                  <span className="d-block">
                    
                    <span className="text-black bold"><a href="https://www.youtube.com/watch?v=jqiVeo8zK-w" target="_blank" rel="noopener noreferrer">{t("nigeria_page_newsfeed_article5_url")}</a></span>
                    <br /><br />
                  </span>

                  <a href="https://www.youtube.com/watch?v=jqiVeo8zK-w" target="_blank" rel="noopener noreferrer"> <img src={NewsfeedSteveThumbnail2} alt="icon" className="newsfeed-photo" /> </a>
                  
                </p>

              </div>
            </div> 

        </SwiperSlide>
        <SwiperSlide>
          
        
        <div className="newsfeed-swiper-slide">
              <div className="review text-center newsfeed">
               
                <a href="https://www.blueprint.ng/kidnapping-i-made-n200m-in-6-months-suspect/" target="_blank" rel="noopener noreferrer"> <h3>{t("nigeria_page_newsfeed_article6_h3")}</h3> </a>
                <blockquote >
                  <p></p>
                </blockquote>

                <p className="review-user newsfeed-photo-container">
                  <span className="d-block">
                   
                    <span className="text-black bold"><a href="https://www.blueprint.ng/kidnapping-i-made-n200m-in-6-months-suspect/" target="_blank" rel="noopener noreferrer">{t("nigeria_page_newsfeed_article6_url")}</a></span>
                    <br /><br />
                  </span>

                  <a href="https://www.blueprint.ng/kidnapping-i-made-n200m-in-6-months-suspect/" target="_blank" rel="noopener noreferrer"> <img src={NewsfeedArticle6} alt="icon" className="newsfeed-photo" /> </a>
                  
                </p>

              </div>
            </div> 

        </SwiperSlide>
      </Swiper> */}
   
  </div>
</section> {/* <!-- End Newsfeed Section --> */}

   {/* <!-- Parallax Section --> */} 
<section className="banner1">
 
</section>

  <section className="section safety-section">

    <div className="container">
      <div className="row justify-content-center text-center mb-5" data-aos="fade">
        
        <h2 className="section-heading">{t("nigeria_page_protecting_safety_h2")}</h2>

      </div>

      <div className="row">
        <div className="col-md-4">
          <div className="step">
            <span className="number">01</span>
            <h3>{t("nigeria_page_protecting_safety_sign_up_h3")}</h3>
            <p>{t("nigeria_page_protecting_safety_sign_up_p")}<br /><br /></p>
          </div>
        </div>
        <div className="col-md-4">
          <div className="step">
            <span className="number">02</span>
            <h3>{t("nigeria_page_protecting_safety_create_zone_h3")}</h3>
            <p>{t("nigeria_page_protecting_safety_create_zone_p")} <br /><br /> </p>
          </div>
        </div>
        <div className="col-md-4">
          <div className="step">
            <span className="number">03</span>
            <h3>{t("nigeria_page_protecting_safety_stay_safe_h3")}</h3>
            <p>{t("nigeria_page_protecting_safety_stay_safe_p")}<br /><br /></p>
          </div>
        </div>
        
      </div>

      <div className="row justify-content-center text-center mb-5" data-aos="fade">
        <div className="col-md-6 mb-5 safety-section-photo">
          <img src={TetheredPhone5Preview} alt="icon" className="img-fluid" />
        </div>
      </div>
    </div>

  </section>

    {/* <!-- Parallax Section --> */}
    <section className="banner2">
    </section>

  <section className="section">
    <div className="container">
      <div className="row align-items-center home-section">
        <h2 className="section-heading" id="stats-visibility-element">{t("nigeria_page_take_back_power_h2")}</h2>
          <div className="me-auto home-power home-power-top " id="home-stats">
          {/* home-stats-hide */}
           <div className="home-stats-container">
            <p><span id="visibilityFeedback"></span></p>
              
            <div className="numbers">
              
              <span className="numbers__window">
              <span className="numbers__window__digit numbers__window__digit--1" data-fake="2642519073">+</span>
              </span> 
              <span className="numbers__window">
              <span className="numbers__window__digit numbers__window__digit--2" data-fake="9207186394">4</span>
              </span>
              <span className="numbers__window">
              <span className="numbers__window__digit numbers__window__digit--3" data-fake="9395216407">9</span>
              </span>
              <span className="numbers__window">
              <span className="numbers__window__digit numbers__window__digit--4" data-fake="4395216407">0</span>
              </span>
              <span className="numbers__window">
              <span className="numbers__window__digit numbers__window__digit--4" data-fake="+395216407">%</span>
              </span>
              <br /> 
              <span className="numbers__window word__window">
              <span>{t("nigeria_page_take_back_power_increase_kidnapping_h3")}</span>
              </span>
          
            </div>

            <p >{t("nigeria_page_take_back_power_increase_kidnapping_p1")} </p>

              <div className="text-align-right">
                <p > <span className="italics source bold">{t("nigeria_page_take_back_power_increase_kidnapping_ref1")}</span> </p>
              </div>
            <br /> 
            <p >{t("nigeria_page_take_back_power_increase_kidnapping_p2")} </p>
            <div className="text-align-right">
              <p > <span className="italics source bold">{t("nigeria_page_take_back_power_increase_kidnapping_ref2")}</span> </p>
              <br />
            </div>

          </div>

            <div className="home-stats-container">
              
              <div className="numbers ">
              
              <span className="numbers__window">
              <span className="numbers__window__digit numbers__window__digit--1" data-fake="2642519073">+</span>
              </span> 
              <span className="numbers__window">
              <span className="numbers__window__digit numbers__window__digit--2" data-fake="9207186394">5</span>
              </span>
              <span className="numbers__window">
              <span className="numbers__window__digit numbers__window__digit--3" data-fake="9395216407">1</span>
              </span>
              <span className="numbers__window">
              <span className="numbers__window__digit numbers__window__digit--4" data-fake="4395216407">4</span>
              </span>
              <span className="numbers__window">
              <span className="numbers__window__digit numbers__window__digit--4" data-fake="+395216407">4</span>
              </span>
              <br /> 
              <span className="numbers__window word__window">
              <span  >{t("nigeria_page_take_back_power_abuducted_h3_1")} <br /> {t("nigeria_page_take_back_power_abuducted_h3_2")}</span>
              </span>
          
            </div>

            <p > {t("nigeria_page_take_back_power_abuducted_p1")} </p>

              <div className="text-align-right">
                <p > <span className="italics source bold">{t("nigeria_page_take_back_power_abuducted_ref1")}</span> </p>
              </div>

            <br /> <p>{t("nigeria_page_take_back_power_abuducted_p2")} </p>
            
            <div className="text-align-right">
              <p > <span className="italics source bold">{t("nigeria_page_take_back_power_abuducted_ref2")}</span> </p>
              <br />
            </div>

            </div>
           
            <div className="home-stats-container third-box">
              
              <div className="numbers ">
              
              <span className="numbers__window">
              <span className="numbers__window__digit numbers__window__digit--1" data-fake="2642519073">+</span>
              </span> 
              <span className="numbers__window">
              <span className="numbers__window__digit numbers__window__digit--2" data-fake="9207186394">$</span>
              </span>
              <span className="numbers__window">
              <span className="numbers__window__digit numbers__window__digit--3" data-fake="9395216407">1</span>
              </span>
              <span className="numbers__window">
              <span className="numbers__window__digit numbers__window__digit--3" data-fake="9395216407">8</span>
              </span>
              
            <br /> 
              <span className="numbers__window word__window">
                <span  >{t("nigeria_page_take_back_power_ransom_paid_h3")}</span>
              </span>
          
          </div>
              <p >{t("nigeria_page_take_back_power_ransom_paid_p1")} </p>
               <div className="text-align-right">
                <p > <span className="italics source bold">{t("nigeria_page_take_back_power_ransom_paid_ref1")}</span> </p>
              </div>
                <br />
            {/* <p><a href="/contact-us" className="btn btn-primary">Coming Soon...</a></p> */}
            {/* <a href="https://play.google.com/store/apps/details?id=com.tetheredgps.tethered" > <img src={GooglePlayBadge} className="google-play-badge" alt="app-badge" /> </a> */}
            {/* Get The App */}
            <br />
          </div>
        </div>
      </div>
    </div>
  </section>

  <section className="section">
    <div className="container">
      <div className="row align-items-center home-section">
        <div className="col-md-4 ms-auto order-2 home-power">
          <h3 className="mb-4">{t("nigeria_page_take_back_power_why_tethered_h3")}</h3>
          <p className="p-mod">{t("nigeria_page_take_back_power_why_tethered_p1")} <br /> <br />

            {t("nigeria_page_take_back_power_why_tethered_p2")} <br /> <br />
            
            {t("nigeria_page_take_back_power_why_tethered_p3")} <br /> <br />
            
            {t("nigeria_page_take_back_power_why_tethered_p4")}</p>
            <br />
          {/* <p><a href="/contact-us" className="btn btn-primary">Coming Soon...</a></p> */}
          {/* Get The App */}

          {/* <div className="badges-container">
            <div className="badges-container-flex">

              <div className="google-badge-container">
                <a href="https://play.app.goo.gl/?link=https://play.google.com/store/apps/details?id=com.tetheredgps.tethered" target="_blank" rel="noopener noreferrer"> <img src={GooglePlayBadge} className="google-play-badge" alt="app-badge" /> </a>
              </div>
                
              <div className="apple-badge-container">
                <a href="https://apps.apple.com/us/app/tethered-gps/id6450391042" > <img src={AppleAppBadge} className="appple-app-badge" alt="app-badge" /> </a> 
              </div>

            </div>
            </div> */}

        </div>
        <div className="col-md-6 home-images" data-aos="fade-right">
          <img src={MotherDaughter} alt="icon" className="img-fluid img-home" />
        </div>
      </div>
    </div>
  </section>

  <section className="section">
    <div className="container">
      <div className="row align-items-center home-section">
        <div className="col-md-4 me-auto home-power">
          <h3 className="mb-4">{t("nigeria_page_take_back_power_protect_yourself_h3")}</h3>
          <p className="p-mod">{t("nigeria_page_take_back_power_protect_yourself_p1")} <br /> <br />

            {t("nigeria_page_take_back_power_protect_yourself_p2")}</p>
            <br />
          {/* <p><a href="/contact-us" className="btn btn-primary">Coming Soon...</a></p> */}
          {/* Get The App */}

          <div className="badges-container">



            <div className="badges-container-flex badges-container-flex-tablet">

              <div className="google-badge-container">
                <a href="https://play.app.goo.gl/?link=https://play.google.com/store/apps/details?id=com.tetheredgps.tethered" target="_blank" rel="noopener noreferrer"> <img src={GooglePlayBadge} className="google-play-badge" alt="app-badge" /> </a>
              </div>
                
              <div className="apple-badge-container">
                <a href="https://apps.apple.com/us/app/tethered-gps/id6450391042" > <img src={AppleAppBadge} className="appple-app-badge" alt="app-badge" /> </a> 
              </div>

            </div>
                


            </div>

        </div>
        <div className="col-md-6 home-images" data-aos="fade-left">
          <img src={PWCEmergingMarkets} alt="icon" className="img-fluid img-home mobile-off" />
        </div>
      </div>
    </div>
  </section>

  <section className="section">
    <div className="container">
      <div className="row align-items-center home-section">

        <div className="col-md-4 ms-auto order-2 home-power">
          <h3 className="mb-4">{t("nigeria_page_take_back_power_enterprise_solutions_h3")}</h3>
          <p className="p-mod">{t("nigeria_page_take_back_power_enterprise_solutions_p1")} <br /> <br />

            {t("nigeria_page_take_back_power_enterprise_solutions_p2")} <br /> <br />
            
            {t("nigeria_page_take_back_power_enterprise_solutions_p3")} <br /> <br />
            
            {t("nigeria_page_take_back_power_enterprise_solutions_p4")} <br /> <br />
            
            {t("nigeria_page_take_back_power_enterprise_solutions_p5")}</p>
            <br />
            <p><a href="https://wa.me/2348068004993" target="_blank" rel="noopener noreferrer" className="btn btn-primary">{t("homepage_take_back_power_enterprise_solutions_coming_soon")}</a></p>
          {/* Enterprise Solutions */}
        </div>
        <div className="col-md-6 home-images" data-aos="fade-right">
          <img src={DECOPhoto5} alt="icon" className="img-fluid img-home" />
        </div>

      </div>
    </div>
  </section>

  <section className="section safety-section">

<div className="container">
  <div className="row justify-content-center text-center mb-5" data-aos="fade">
    
    <h2 className="section-heading">{t("nigeria_page_payment_methods_h2")}</h2>

  </div>


  <div className="nigeria-payments-container">

    
      <div className="step">
        
        <h3>{t("nigeria_page_payment_methods_google_h3")}</h3>
        <ul>
        <li>{t("nigeria_page_payment_methods_google1_p")}</li>
        <li>{t("nigeria_page_payment_methods_google2_p")}</li>
        <a href="https://www.myverveworld.com/" target="_blank" rel="noopener noreferrer"><li>{t("nigeria_page_payment_methods_google3_p")}</li></a>
        </ul>
        <div className="google-badge-container">
          <a href="https://play.app.goo.gl/?link=https://play.google.com/store/apps/details?id=com.tetheredgps.tethered" target="_blank" rel="noopener noreferrer"> <img src={GooglePlayBadge} className="google-play-badge" alt="app-badge" /> </a>
        </div>
      </div>
    
    
      <div className="step">
        
        <h3>{t("nigeria_page_payment_methods_apple_h3")}</h3>
        <ul>
        <li>{t("nigeria_page_payment_methods_apple1_p")}</li>
        <li>{t("nigeria_page_payment_methods_apple2_p")}</li>
        </ul>
        <ul></ul>
        <ul ></ul>
        <div className="tablet-hide"><br ></br></div>
        <div className="apple-badge-container">
          <a href="https://apps.apple.com/us/app/tethered-gps/id6450391042" target="_blank" rel="noopener noreferrer"> <img src={AppleAppBadge} className="appple-app-badge" alt="app-badge" /> </a> 
        </div>
      </div>
    
    
    
  </div>

  
</div>

</section>

 {/* <!-- Parallax Section --> */}
   
    <section className="banner3"> 
   </section> 
  
</main>
{/* <!-- End #main --> */}

{/* <!-- Template Main JS File --> */}
<script src="scripts/stats-counter.js"></script>

{/* <!-- Newsfeed/Testimonial Slider JS File --> */}
 <script src="scripts/testimonials.js"></script>

</div>
    
  )
}
 