// styles
import './Solutions.css'
import React from 'react';
// import LoadOnce from "../../scripts/loadOnce.js"
// import Shield from "@mui/icons-material/ShieldOutlined.js";
// import PoliceShield from "@mui/icons-material/LocalPoliceOutlined.js";
// import FamilyGold from "@mui/icons-material/Diversity1Outlined.js";
// import { t } from 'i18next';

import { useTranslation } from "react-i18next";

export default function Solutions() {

  const { t } = useTranslation();

  return (
    <div>

      {/* <!-- ======= Hero Section ======= --> */}
      <section className="hero-section" id="hero">



        <div className="container">
          <div className="row align-items-center">
            <div className="col-12 hero-text-image">
              <div className="row">
                <div className="col-lg-8 text-center text-lg-start">
                  <h1 data-aos="fade-right">{t("pricing_h1")} <br />
                  </h1>

                  <p className="mb-5" data-aos="fade-right" data-aos-delay="100">{t("pricing_subhead_p")}</p>

                </div>
                <br />
              </div>
            </div>
          </div>
        </div>

      </section>{/* <!-- End Hero --> */}

      <main id="main">

        {/* <!-- NEW PRICING SECTION --> */}

        <section className="section">
          <div className="container">

            <div className="row justify-content-center text-center">
              <div className="col-md-7 mb-5">
                {/* <h2 className="section-heading">{t("pricing_h2_choose_plan")}</h2> */}

              </div>
            </div>

            <div className="row align-items-stretch pricing-container">

{/* Aca va el codigo que borramos */}

              <div className="col-3 pricing-columns">
                <div className="pricing h-100 text-center popular silver faded">
                  <span className="popularity smaller no-outline">{t("pricing_heading_value1")}</span>
                  <h3 className="price-title"> {t("pricing_h3_silver")} </h3>
                  {/* <Shield className="material-icon sizing" /> */}

                  <ul className="list-unstyled">                  
                    <a className='solutions-a' href="#features">
                      <li className="li-features">
                        <div className='solutions-column-check'>
                          <div><i className='bx bx-check silver-check'></i></div>
                          <div>{t("pricing_features_li1")}</div>
                        </div>
                      </li>
                    </a>
                    <li></li>
                    <li></li>
                    <li></li>
                    <li></li>
                    <li></li>
                    <li></li>
                  </ul>
                  <div className="price-cta price-white">

                  </div>
                </div>
              </div>
              <div className="col-3 pricing-columns">
                <div className="pricing h-100 text-center popular gold">
                  <span className="popularity bold italics outlined">{t("pricing_heading_popular")}</span>
                  <h3 className="price-title"> {t("pricing_h3_gold")} </h3>
                  {/* <PoliceShield className="material-icon sizing" /> */}
                  <ul className="list-unstyled">
                    <a className='solutions-b' href="#features">
                      <li className="li-features">
                        <div className='solutions-column-check'>
                          <div><i className='bx bx-check gold-check'></i></div>
                          <div>{t("pricing_features_li1")}</div>
                        </div>
                      </li>
                    </a>
                    <a className='solutions-b' href="#features">
                      <li className="li-features">
                        <div className='solutions-column-check'>
                          <div><i className='bx bx-check gold-check'></i></div>
                          <div>{t("pricing_features_li2")}</div>
                        </div>
                      </li>
                    </a>             
               
                    <a className='solutions-b' href="#features">
                      <li className="li-features">
                        <div className='solutions-column-check'>
                          <div><i className='bx bx-check gold-check'></i></div>
                          <div>{t("pricing_features_li3")}</div>
                        </div>
                      </li>
                    </a>
                   
                    <a className='solutions-b' href="#features">
                      <li className="li-features">
                        <div className='solutions-column-check'>
                          <div><i className='bx bx-check gold-check'></i></div>
                          <div>{t("pricing_features_li4")}</div>
                        </div>
                      </li>
                    </a>

                    <a className='solutions-b' href="#features">
                      <li className="li-features">
                        <div className='solutions-column-check'>
                          <div><i className='bx bx-check gold-check'></i></div>
                          <div>{t("pricing_features_li5")}</div>
                        </div>
                      </li>
                    </a>
                    <br></br><br></br><br></br><br></br><br></br><br></br>
                    <li><h3 className='gold'>{t("pricing_features_gold_family_h3")}</h3></li>

                    <li></li>
                    <li></li>
                  </ul>
                  <div className="price-cta price-white">

                    {/* <p><a href="https://play.google.com/store/apps" className="btn btn-blue">₦10,000/month</a></p>
                <p><a href="https://play.google.com/store/apps" className="btn btn-blue">₦96,000/year</a></p> */}

                    {/* <h3 >{t("pricing_h3_gold_monthly")}</h3>
                <br></br>
                <h3 >{t("pricing_h3_gold_yearly")}</h3>
                <p className="subtext">({t("pricing_p_savings2")})</p>
                <br></br> */}
                  </div>
                </div>
              </div>

              <div className="col-3 pricing-columns" >
                <div className="pricing h-100 text-center popular faded family">
                  <span className="popularity smaller no-outline">{t("pricing_heading_value")}</span>
                  <h3 className="price-title"> {t("pricing_heading_h3_elite")} </h3>
                  {/* <FamilyGold className="material-icon sizing" /> */}
                  <ul className="list-unstyled">
                  <a className='solutions-b' href="#features">
                      <li className="li-features">
                        <div className='solutions-column-check'>
                          <div><i className='bx bx-check gold-check'></i></div>
                          <div>{t("pricing_features_li1")}</div>
                        </div>
                      </li>
                    </a>
                    <a className='solutions-b' href="#features">
                      <li className="li-features">
                        <div className='solutions-column-check'>
                          <div><i className='bx bx-check gold-check'></i></div>
                          <div>{t("pricing_features_li2")}</div>
                        </div>
                      </li>
                    </a>             
               
                    <a className='solutions-b' href="#features">
                      <li className="li-features">
                        <div className='solutions-column-check'>
                          <div><i className='bx bx-check gold-check'></i></div>
                          <div>{t("pricing_features_li3")}</div>
                        </div>
                      </li>
                    </a>
                   
                    <a className='solutions-b' href="#features">
                      <li className="li-features">
                        <div className='solutions-column-check'>
                          <div><i className='bx bx-check gold-check'></i></div>
                          <div>{t("pricing_features_li4")}</div>
                        </div>
                      </li>
                    </a>

                    <a className='solutions-b' href="#features">
                      <li className="li-features">
                        <div className='solutions-column-check'>
                          <div><i className='bx bx-check gold-check'></i></div>
                          <div>{t("pricing_features_li5")}</div>
                        </div>
                      </li>
                    </a>
                    {/* <li><i className='bx bx-check gold-check'></i></li> */}
                    <a className='solutions-b' href="#features">
                      <li className="li-features">
                        <div className='solutions-column-check'>
                          <div><i className='bx bx-check gold-check'></i></div>
                          <div>{t("pricing_features_li6")}</div>
                        </div>
                      </li>
                    </a>
                    {/* <li><i className='bx bx-check gold-check'></i></li> */}
                    <a className='solutions-b' href="#features">
                      <li className="li-features">
                        <div className='solutions-column-check'>
                          <div><i className='bx bx-check gold-check'></i></div>
                          <div>{t("pricing_features_li7")}</div>
                        </div>
                      </li>
                    </a>
                    {/* <li><i className='bx bx-check gold-check'></i></li>
                <li><i className='bx bx-check gold-check'></i></li>
                <li><i className='bx bx-check gold-check'></i></li>
                <li><i className='bx bx-check gold-check'></i></li> */}
                    <li></li>
                    <li></li>
                    <li></li>
                  </ul>
                  <div className="price-cta price-white">

                    {/* <p><a href="https://play.google.com/store/apps" className="btn btn-white">₦25,000/month</a></p>
                <p><a href="https://play.google.com/store/apps" className="btn btn-white">Not Available</a></p> */}
                    {/* <h3 className="only-monthly">{t("pricing_h3_gold_family_monthly")}</h3>
                <br className="desktop-hidden"></br>
                <br></br>
                <br></br>
                <br></br> */}
                  </div>
                </div>
              </div>

              {/* Family Package */}

              {/* <div className="col-3 pricing-columns" >
            <div className="pricing h-100 text-center popular faded family">
              <span className="popularity smaller no-outline">{t("pricing_heading_value")}</span>
              <h3 className="price-title"><FamilyGold className="material-icon sizing" /> {t("pricing_heading_h3_gold_family")} </h3>
              <ul className="list-unstyled">
                <li><i className='bx bx-check gold-check'></i></li>
                <li><i className='bx bx-check gold-check'></i></li>
                <li><i className='bx bx-check gold-check'></i></li>
                <li><i className='bx bx-check gold-check'></i></li>
                <li><i className='bx bx-check gold-check'></i></li>
                <li><i className='bx bx-check gold-check'></i></li>
                <li><i className='bx bx-check gold-check'></i></li>
                <li></li>
                <li></li>
                <li></li>
              </ul>
              <div className="price-cta price-white">
              </div>
            </div>
          </div> */}



            </div>
            <br></br>
            <h3 id="features"> {t("pricing_download_cta_h3")}</h3>
            <br></br>
            <p>* {t("pricing_payment_p")}</p>

            <div className="feature-descriptions">
              <br></br><br></br>
              <h3 > {t("pricing_features_h3")}</h3>
              <br></br>
              <p><strong>{t("pricing_features_1_part1")}</strong> {t("pricing_features_1_part2")}</p>
              <p><strong>{t("pricing_features_2_part1")}</strong> {t("pricing_features_2_part2")}</p>
              <p><strong>{t("pricing_features_3_part1")}</strong> {t("pricing_features_3_part2")}</p>
              <p><strong>{t("pricing_features_4_part1")}</strong> {t("pricing_features_4_part2")} </p>
              <p><strong>{t("pricing_features_5_part1")}</strong> {t("pricing_features_5_part2")} </p>
              <p><strong>{t("pricing_features_6_part1")}</strong> {t("pricing_features_6_part2")}</p>
              <p><strong>{t("pricing_features_7_part1")}</strong> {t("pricing_features_7_part2")}</p>
              <p><strong>{t("pricing_features_8_part1")}</strong> {t("pricing_features_8_part2")}</p>
            </div>

          </div>
        </section>

      </main> {/*<!-- End #main --> */}

    </div>
  )
}
