// styles
import './Homepage.css'

// images import 
import {
  HeaderPhone1,
  HeaderPhone2,
  GooglePlayBadge,
  LinkTreeQRCode,
  AppleAppBadge,
  FlaticonWorkTeam,
  FlaticonCreativeProcess,
  FlaticonCustomerReview,
  FlaticonPadlockKey,
  MotherDaughter,
  PWCEmergingMarkets,
  DECOPhoto5,
  TetheredPhoneAustinPreview,
  Phone_1,
  Phone_2,
  Phone_3,
  Phone_4,
} from '../../headers/photos.js' 

import React from "react";
import { useTranslation } from "react-i18next";

export default function Homepage() {
  
  const { t } = useTranslation();
  console.log('browser language is : ' + navigator.language)

  return (
    <div>
      {/* <!-- ======= Hero Section ======= --> */}
      <section className="hero-section hero-section-homepage" id="hero">
        <div className="container">
          <div className="row align-items-center">
            <div className="col-12 hero-text-image">
              <div className="row">
                <div className="col-lg-8 text-center text-lg-start ">
                  <h1 data-aos="fade-right">
                  {t("homepage_h1_part1")}  <br />{t("homepage_h1_part2")}</h1>
                  <p className="mb-5" data-aos="fade-right" data-aos-delay="100">  
                  {t("homepage_header_subhead_p")} <br></br>
                  </p>
                  <div className="badges-container">
                    <div className="badges-container-flex">
                      <div className="google-badge-container">
                        <a href="https://play.app.goo.gl/?link=https://play.google.com/store/apps/details?id=com.tetheredgps.tethered" target="_blank" rel="noopener noreferrer"> <img src={GooglePlayBadge} className="google-play-badge" alt="app-badge" /> </a>
                      </div>
                      <div className="apple-badge-container">
                        <a href="https://apps.apple.com/us/app/tethered-gps/id6450391042" target="_blank" rel="noopener noreferrer"> <img src={AppleAppBadge} className="appple-app-badge" alt="app-badge" /> </a> 
                      </div>
                    </div>     
                    {<img src={LinkTreeQRCode} className="qr-code" alt="app-badge" /> }
                  </div>
                  <br />
                </div>
                <div className="col-lg-4 iphone-wrap">
                  <img src={HeaderPhone1} alt="phone" className="phone-1" data-aos="fade-right" data-aos-delay="200" />
                  <img src={HeaderPhone2} alt="phone" className="phone-2" data-aos="fade-right" />
                </div> 
              </div>
            </div>
          </div>
        </div>
      </section>{/* <!-- End Hero --> */}

      <main id="main">

      {/* <!-- ======= Home Section ======= --> */}
        
        <section className="section">
          <div className="container">
            <div className="row justify-content-center text-center mb-5">
              <div  data-aos="fade-up">
                <h2 className="section-heading home-page-h2">{t("homepage_dont_go_alone_h2")} </h2>
              </div>
            </div>
            <div className="row">
              <div className="home-safety-container">
                <div className="home-safety"> 
                  <div className="home-safety-box" data-aos="fade-up" data-aos-delay="">
                    <div className="feature-1 text-center">
                      <div className="home-safety-box-inner">
                      <div className="wrap-icon icon-1">
                        <img className="graphic-icon" src={FlaticonWorkTeam} alt="icon"/>
                      </div>
                      <h3 className="mb-3">{t("homepage_dont_go_alone_h3_improve_safety")}</h3>
                      <p>{t("homepage_dont_go_alone_p_improve_safety")}</p>
                    </div>
                  </div>
                  </div>
                  <div className="home-safety-box" data-aos="fade-up" data-aos-delay="100">
                    <div className="feature-1 text-center">
                      <div className="home-safety-box-inner">
                      <div className="wrap-icon icon-1">
                        <img className="graphic-icon" src={FlaticonCreativeProcess} alt="icon" />
                      </div>
                      <h3 className="mb-3">{t("homepage_dont_go_alone_h3_know_before")}</h3>
                      <p>{t("homepage_dont_go_alone_p_know_before")}</p>
                    </div>
                  </div>
                  </div>
                  <div className="home-safety-box" data-aos="fade-up" data-aos-delay="200">
                    <div className="feature-1 text-center">
                      <div className="home-safety-box-inner">
                      <div className="wrap-icon icon-1">
                        <img className="graphic-icon" src={FlaticonCustomerReview} alt="icon" />
                      </div>
                      <h3 className="mb-3">{t("homepage_dont_go_alone_h3_protect_employees")}</h3>
                      <p>{t("homepage_dont_go_alone_p_protect_employees")}</p>
                    </div>
                  </div>
                  </div>
                  <div className="home-safety-box" data-aos="fade-up" data-aos-delay="">
                    <div className="feature-1 text-center">
                    <div className="home-safety-box-inner">
                      <div className="wrap-icon icon-1">
                        <img className="graphic-icon" src={FlaticonPadlockKey} alt="icon" />
                      </div>
                      <h3 className="mb-3">{t("homepage_dont_go_alone_h3_victim")}</h3>
                      <p>{t("homepage_dont_go_alone_p_victrim")}</p>
                    </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        {/* <!-- Parallax Section --> */} 
        <section className="banner-american-1">
        </section>

        <section className="section safety-section">
          <div className="container">
            <div className="row justify-content-center text-center mb-5" data-aos="fade">
              <h2 className="section-heading">{t("homepage_protecting_safety_h2")}</h2>
            </div>
            <div className="row">
              <div className="col-md-4">
                <div className="step">
                  <span className="number">01</span>
                  <h3>{t("homepage_protecting_safety_sign_up_h3")}</h3>
                  <p>{t("homepage_protecting_safety_sign_up_p")}<br /><br /></p>
                </div>
              </div>
              <div className="col-md-4">
                <div className="step">
                  <span className="number">02</span>
                  <h3>{t("homepage_protecting_safety_create_zone_h3")}</h3>
                  <p>{t("homepage_protecting_safety_create_zone_p")} <br /><br /> </p>
                </div>
              </div>
              <div className="col-md-4">
                <div className="step">
                  <span className="number">03</span>
                  <h3>{t("homepage_protecting_safety_stay_safe_h3")}</h3>
                  <p>{t("homepage_protecting_safety_stay_safe_p")}<br /><br /></p>
                </div>
              </div>
            </div>
            <div className="row justify-content-center text-center mb-5" data-aos="fade">
              <div className="col-md-6 mb-5 safety-section-photo">
                <img src={TetheredPhoneAustinPreview} alt="icon" className="img-fluid" />
              </div>
            </div>
          </div>
        </section>

        {/* <!-- The App Section --> */}

        <section className="section">
          <div className="container">
            <div className="row justify-content-center text-center mb-5 home-section">
              <div className="col-md-5" data-aos="fade-up">
                <h2 className="section-heading about-h2">{t("about_us_app_h2")}</h2>
              </div>
            </div>
            <div className="row align-items-center home-section">
              <div className="col-md-4 me-auto home-power">
                <h3 className="mb-4 about-h3">{t("about_us_app_h3")} </h3>
                <p className="mb-4"> {t("about_us_app_p_part1")} <br /> <br />
                {t("about_us_app_p_part2")} 
                </p>
              </div>
              <div className="col-md-6 home-images" data-aos="fade-left">
                <img src={Phone_1} alt="phone" className="img-fluid img-about mobile-off" />
              </div>
            </div>
          </div>
        </section>

        <section className="section">
          <div className="container">
            <div className="row align-items-center home-section">
              <div className="col-md-4 ms-auto order-2 home-power">
                <h3 className="mb-4 about-h3">{t("about_us_peace_mind_h3")} </h3>
                <p className="mb-4">{t("about_us_peace_mind_p_part1")} <br /> <br />
                  {t("about_us_peace_mind_p_part2")} <br /> <br />
                  {t("about_us_peace_mind_p_part3")} <br /> <br />
                  {t("about_us_peace_mind_p_part4")}</p>
              </div>
              <div className="col-md-6 home-images" data-aos="fade-right">
                <img src={Phone_2} alt="phone" className="img-fluid img-about" />
              </div>
            </div>
          </div>
        </section>

        <section className="section">
          <div className="container">
            <div className="row align-items-center home-section">
              <div className="col-md-4 me-auto home-power">
                <h3 className="mb-4 about-h3">{t("about_us_contacts_h3")}</h3>
                <p className="mb-4">{t("about_us_contacts_p_part1")} <br /> <br />
                {t("about_us_contacts_p_part2")} <br /> <br /> 
                {t("about_us_contacts_p_part3")}
                </p>
              </div>
              <div className="col-md-6 home-images" data-aos="fade-left">
                <img src={Phone_3} alt="phone" className="img-fluid img-about mobile-off" />
              </div>
            </div>
          </div>
        </section>
        <section className="section">
          <div className="container">
            <div className="row align-items-center home-section">
              <div className="col-md-4 ms-auto order-2 home-power">
                <h3 className="mb-4 about-h3">{t("about_us_panic_h3")} </h3>
                <p className="mb-4"> {t("about_us_panic_p_part1")} <br /> <br />
                {t("about_us_panic_p_part2")} <br /> <br />
              {t("about_us_panic_p_part3")}</p>  
              </div>
              <div className="col-md-6 home-images" data-aos="fade-right">
                <img src={Phone_4} alt="phone" className="img-fluid img-about" />
              </div>
            </div>
          </div>
        </section>

        {/* <!-- Parallax Section --> */}
        <section className="banner2">
        </section>

        <section className="section">
          <div className="container">
            <div className="row align-items-center home-section">
              <h2 className="section-heading" id="stats-visibility-element">{t("homepage_take_back_power_h2")}</h2>
            </div>
          </div>
        </section>

        <section className="section">
          <div className="container">
            <div className="row align-items-center home-section">
              <div className="col-md-4 ms-auto order-2 home-power">
                <h3 className="mb-4">{t("homepage_take_back_power_why_tethered_h3")}</h3>
                <p className="p-mod">{t("homepage_take_back_power_why_tethered_p1")} <br /> <br />
                  {t("homepage_take_back_power_why_tethered_p2")} <br /> <br />
                  {t("homepage_take_back_power_why_tethered_p3")} <br /> <br />
                  {t("homepage_take_back_power_why_tethered_p4")}</p>
                  <br />
              </div>
              <div className="col-md-6 home-images" data-aos="fade-right">
                <img src={MotherDaughter} alt="icon" className="img-fluid img-home" />
              </div>
            </div>
          </div>
        </section>

        <section className="section">
          <div className="container">
            <div className="row align-items-center home-section">
              <div className="col-md-4 me-auto home-power">
                <h3 className="mb-4">{t("homepage_take_back_power_protect_yourself_h3")}</h3>
                <p className="p-mod">{t("homepage_take_back_power_protect_yourself_p1")} <br /> <br />
                  {t("homepage_take_back_power_protect_yourself_p2")}</p>
                  <br />
                <div className="badges-container">
                  <div className="badges-container-flex badges-container-flex-tablet">
                    <div className="google-badge-container">
                      <a href="https://play.app.goo.gl/?link=https://play.google.com/store/apps/details?id=com.tetheredgps.tethered" target="_blank" rel="noopener noreferrer"> <img src={GooglePlayBadge} className="google-play-badge" alt="app-badge" /> </a>
                    </div>
                    <div className="apple-badge-container">
                      <a href="https://apps.apple.com/us/app/tethered-gps/id6450391042" > <img src={AppleAppBadge} className="appple-app-badge" alt="app-badge" /> </a> 
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-md-6 home-images" data-aos="fade-left">
                <img src={PWCEmergingMarkets} alt="icon" className="img-fluid img-home mobile-off" />
              </div>
            </div>
          </div>
        </section>

        <section className="section">
          <div className="container">
            <div className="row align-items-center home-section">
              <div className="col-md-4 ms-auto order-2 home-power">
                <h3 className="mb-4">{t("homepage_take_back_power_enterprise_solutions_h3")}</h3>
                <p className="p-mod">{t("homepage_take_back_power_enterprise_solutions_p1")} <br /> <br />
                  {t("homepage_take_back_power_enterprise_solutions_p2")} <br /> <br />
                  {t("homepage_take_back_power_enterprise_solutions_p3")} <br /> <br />
                  {t("homepage_take_back_power_enterprise_solutions_p4")} <br /> <br />
                  {t("homepage_take_back_power_enterprise_solutions_p5")}</p>
                  <br />
                  <p><a href="https://wa.me/2348068004993" target="_blank" rel="noopener noreferrer" className="btn btn-primary">{t("homepage_take_back_power_enterprise_solutions_coming_soon")}</a></p>
              </div>
              <div className="col-md-6 home-images" data-aos="fade-right">
                <img src={DECOPhoto5} alt="icon" className="img-fluid img-home" />
              </div>
            </div>
          </div>
        </section>

        {/* <!-- Parallax Section --> */}
        
        <section className="banner3"> 
        </section> 
        
      </main>
      {/* <!-- End #main --> */}

      {/* <!-- Template Main JS File --> */}
      <script src="scripts/stats-counter.js"></script>

      {/* <!-- Newsfeed/Testimonial Slider JS File --> */}
      <script src="scripts/testimonials.js"></script>

    </div>
  )
}