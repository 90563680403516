// styles
import './Footer.css'

// images import 
import SocialLinks from './social-links.js'
import React from "react";
import { useTranslation } from "react-i18next";

export default function Footer() {
    
  const { t } = useTranslation();

    return (
      <div className="footer-content">
         <div>

 {/* <!-- ======= Testimonials Section ======= --> */}

 {/* <section className="section border-top border-bottom">
  <div className="container testimonial-section">
    <div className="row justify-content-center text-center mb-5">
      
        <h2 className="section-heading">What People Are Saying:</h2>
      
    </div>

    <Swiper
        effect={"coverflow"}
        grabCursor={true}
        centeredSlides={true}
        slidesPerView={"auto"}
        coverflowEffect={{
          rotate: 0,
          stretch: 0,
          depth: 100,
          modifier: 1,
          slideShadows: false,
        }}
        pagination={true}
        autoplay={{ 
          delay: 3000,
        }}
        className="mySwiper"
      >
        <SwiperSlide>
        <div className="testimonial-swiper-slide">
              <div className="review text-center">
                <p className="stars">
                  <span className="bi bi-star-fill"></span>
                  <span className="bi bi-star-fill"></span>
                  <span className="bi bi-star-fill"></span>
                  <span className="bi bi-star-fill"></span>
                  <span className="bi bi-star-fill muted"></span>
                </p>
                <h3>(Testimonials Coming Soon)</h3>
                <blockquote>
                  <p>...</p>
                </blockquote>

                <p className="review-user">
                  <img src={TestimonialPhoto1} alt="user-review" className="img-fluid rounded-circle mb-3" />
                  <span className="d-block">
                    <span className="text-black">Jean Doe</span>, &mdash; App User
                  </span>
                </p>

              </div>
            </div> 
        </SwiperSlide>
        <SwiperSlide>
          
        <div className="testimonial-swiper-slide">
              <div className="review text-center">
                <p className="stars">
                  <span className="bi bi-star-fill"></span>
                  <span className="bi bi-star-fill"></span>
                  <span className="bi bi-star-fill"></span>
                  <span className="bi bi-star-fill"></span>
                  <span className="bi bi-star-fill muted"></span>
                </p>
                <h3>(Testimonials Coming Soon)</h3>
                <blockquote>
                  <p>...</p>
                </blockquote>

                <p className="review-user">
                  <img src={TestimonialPhoto2} alt="user-review" className="img-fluid rounded-circle mb-3" />
                  <span className="d-block">
                    <span className="text-black">Johan Smith</span>, &mdash; App User
                  </span>
                </p>

              </div>
            </div>

        </SwiperSlide>
        <SwiperSlide>
          
        <div className="testimonial-swiper-slide">
              <div className="review text-center">
                <p className="stars">
                  <span className="bi bi-star-fill"></span>
                  <span className="bi bi-star-fill"></span>
                  <span className="bi bi-star-fill"></span>
                  <span className="bi bi-star-fill"></span>
                  <span className="bi bi-star-fill muted"></span>
                </p>
                <h3>(Testimonials Coming Soon)</h3>
                <blockquote>
                  <p>...</p>
                </blockquote>

                <p className="review-user">
                  <img src={TestimonialPhoto3} alt="user-review" className="img-fluid rounded-circle mb-3" />
                  <span className="d-block">
                    <span className="text-black">Jean Thunberg</span>, &mdash; App User
                  </span>
                </p>
              </div>
            </div>
        </SwiperSlide>
      </Swiper>
  </div>
</section> */}

{/* <!-- End Testimonials Section --> */}

{/* <!-- ======= CTA Section ======= --> */}
<section className="section cta-section">
    <div className="cta-container">
      <div className="cta-text">
        <h3>"{t("footer_improve_security_h3_part1")}"<br></br>{t("footer_improve_security_h3_part2")}</h3>
      </div>
    </div>
</section>{/* <!-- End CTA Section --> */}

   {/* <!-- ======= Footer ======= --> */}
   <footer className="footer" role="contentinfo">
    <div className="container">
      <div className="row">
        <div className="col-md-4 mb-4 mb-md-0 footer-nav-about footer-navigation">
          <h3>{t("footer_about_tethered_h3")}</h3>
          <p>{t("footer_about_tethered_p")}</p>
          <p className="social">
          <SocialLinks></SocialLinks>
          </p>
        </div>
        <div className="col-md-7 ms-auto">
          <div className="row site-section pt-0">
            <div className="col-md-4 mb-4 mb-md-0 footer-navigation">
              <h3>{t("footer_about_navigation_h3")}</h3>
              <ul className="list-unstyled">
                <li><a href="tutorials-create-a-safety-zone" >{t("footer_about_navigation_tutorials")}</a></li>
                <li><a href="faq" >{t("footer_about_navigation_faq")}</a></li>
                <li><a href="contact-us" >{t("footer_about_navigation_contact")}</a></li>
                <li><a href="jobs" >{t("footer_about_navigation_jobs")}</a></li>
              </ul>
            </div>
            <div className="col-md-4 mb-4 mb-md-0 footer-navigation">
              <h3>{t("footer_legal_h3")}</h3>
              <ul className="list-unstyled">
                <li><a href="privacy-policy" target="_blank" rel="noopener noreferrer">{t("footer_legal_privacy")}</a></li>
                <li><a href="cookies-policy" target="_blank" rel="noopener noreferrer">{t("footer_legal_cookies")}</a></li>
                <li><a href="terms-and-conditions" target="_blank" rel="noopener noreferrer">{t("footer_legal_terms")}</a></li>
              </ul>
            </div>
            <div className="col-md-4 mb-4 mb-md-0 footer-downloads footer-navigation">
              <h3>{t("footer_downloads_h3")}</h3>
              <ul className="list-unstyled">
                <li><a href="https://drive.google.com/drive/folders/1AarQ0E2yypl7WGEpq7zU-XfaNDF7UGzl?usp=sharing" target="_blank" rel="noopener noreferrer">{t("footer_downloads_medie_kit")}</a></li>
              </ul>
            </div>
          </div>
        </div>
      </div>

      <div className="row justify-content-center text-center">
        <div className="col-md-7">
          <p className="copyright">&copy; {t("footer_copyright_p")} <br />  </p>
          {/* Icon made by <a href="https://www.flaticon.com/authors/freepik" target="_blank" rel="noopener noreferrer">Freepik</a> from <a href="https://www.flaticon.com/" target="_blank" rel="noopener noreferrer"> www.flaticon.com</a> */}
          <div className="credits">
          </div>
        </div>
      </div>
    </div>
  </footer>

  {/* <!-- Testimonial Slider JS File --> */}
  <script src="scripts/testimonials.js"></script>

    </div>
  </div>
  )
}