// styles
import './Mexico.css'


// images import 
import Phone1 from '../../assets/img/tethered-phone-img-5-compress2.webp'
import Phone2 from '../../assets/img/tethered-phone-img-1-compress2.webp'
import FlaticonAlert from '../../assets/img/icons/flaticon-alert.png'
import FlaticonMap from '../../assets/img/icons/flaticon-map-marker.png'
import FlaticonCustomerReview from '../../assets/img/icons/customer-reviews-flaticon.png'
import FlaticonAgents from '../../assets/img/icons/flaticon-security-team.png'
import NewsfeedPremiumTimesKidnapping from '../../assets/img/newsfeed/premium-times-kidnapping-snapshot-compress.webp'
import TetheredPhoneAustinPreview from '../../assets/img/Tethered_Austin_Capital-removebg-preview.png'
import Mexico1 from '../../assets/img/tethered_mexico_1.png'
import Mexico2 from '../../assets/img/tethered_mexico_2.png'
import Mexico3 from '../../assets/img/tethered_mexico_3.png'
import GooglePlayBadge from '../../assets/img/icons/google-play-badge.png'
import AppleAppBadge from '../../assets/img/icons/Download_on_the_App_Store_Badge_US-UK_RGB_blk_092917.svg'

import React from "react";

import Swiper from 'swiper';
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import SwiperCore, { Autoplay, Navigation, EffectCoverflow, Pagination } from "swiper/modules";

// import { Swiper, SwiperSlide } from "swiper/react";
// import "swiper/swiper-bundle.min.css";
// import "swiper/swiper.min.css";
// import "./App.css";

import { useTranslation } from "react-i18next";


// SwiperCore.use([Autoplay, Navigation, EffectCoverflow, Pagination]);


export default function Mexico() {

  const { t } = useTranslation();
  
  return (

<div>

{/* <!-- ======= Hero Section ======= --> */}

<section className="hero-section hero-section-homepage" id="hero">

  

  <div className="container">
    <div className="row align-items-center">
      <div className="col-12 hero-text-image">
        <div className="row">
          <div className="col-lg-8 text-center text-lg-start">
            <h1 data-aos="fade-right">
              {t("mexico_h1_part1")} <br />{t("mexico_h1_part2")}</h1>
             <p className="mb-5" data-aos="fade-right" data-aos-delay="100">  
               {t("mexico_subhead_p")} </p>


          <div className="badges-container">



            <div className="badges-container-flex">

              <div className="google-badge-container">
                <a href="https://play.app.goo.gl/?link=https://play.google.com/store/apps/details?id=com.tetheredgps.tethered" target="_blank" rel="noopener noreferrer"> <img src={GooglePlayBadge} className="google-play-badge" alt="app-badge" /> </a>
              </div>
                
              <div className="apple-badge-container">
                <a href="https://apps.apple.com/us/app/tethered-gps/id6450391042" > <img src={AppleAppBadge} className="appple-app-badge" alt="app-badge" /> </a> 
              </div>

            </div>
          


          </div>

            {/* <p data-aos="fade-right" data-aos-delay="200" data-aos-offset="-500"><a href="/contact-us" className="btn btn-outline-white">Contact Sales</a></p>  */}
            {/* Get The App */}
            <br />
          </div>
          
           <div className="col-lg-4 iphone-wrap">
            <img src={TetheredPhoneAustinPreview} alt="phone" className="phone-1" data-aos="fade-right" data-aos-delay="200" />
            <img src={Phone2} alt="phone" className="phone-2" data-aos="fade-right" />
            
            
          </div> 
        </div>
      </div>
    </div>
  </div>

</section>{/* <!-- End Hero --> */}

<main id="main">

{/* <!-- ======= Home Section ======= --> */}
  
  <section className="section">
    <div className="container">

   

      <div className="row justify-content-center text-center mb-5">
        <div  data-aos="fade-up">
          <h2 className="section-heading home-page-h2">{t("mexico_personal_security_h2")} </h2>
        </div>
      </div>

      <div className="row">

        <div className="home-safety-container">
        <div className="home-safety"> 

        <div className="home-safety-box" data-aos="fade-up" data-aos-delay="">
          <div className="feature-1 text-center">

            <div className="home-safety-box-inner">

            <div className="wrap-icon icon-1">

              <img className="graphic-icon" src={FlaticonAlert} alt="icon"/>
            </div>
            <h3 className="mb-3">{t("mexico_personal_security_SOS_h3")}</h3>
            {/* <p>Take steps to improve your personal safety and security, as you move about your day, by using the latest in security monitoring technology.</p> */}
          </div>

        </div>

        </div>

        <div className="home-safety-box" data-aos="fade-up" data-aos-delay="100">
          <div className="feature-1 text-center">
            
            <div className="home-safety-box-inner">
            
            <div className="wrap-icon icon-1">
              <img className="graphic-icon" src={FlaticonMap} alt="icon" />
            </div>
            <h3 className="mb-3">{t("mexico_personal_security_custom_h3")}</h3>
            {/* <p>Gain access to security and safety travel information before you make your travel arrangements.</p> */}
          </div>
        </div>
        </div>

        <div className="home-safety-box" data-aos="fade-up" data-aos-delay="200">
          <div className="feature-1 text-center">

            <div className="home-safety-box-inner">

            <div className="wrap-icon icon-1">
              <img className="graphic-icon" src={FlaticonCustomerReview} alt="icon" />
            </div>
            <h3 className="mb-3">{t("mexico_personal_security_emergency_h3")}</h3>
            {/* <p>Meet your duty of care by properly protecting your employees, when they travel for your organization or government agency... You need to know they are safe 24/7.</p> */}
          </div>
        </div>
        </div>

        <div className="home-safety-box" data-aos="fade-up" data-aos-delay="">
          <div className="feature-1 text-center">

          <div className="home-safety-box-inner">

            <div className="wrap-icon icon-1">
              <img className="graphic-icon" src={FlaticonAgents} alt="icon" />
            </div>
            <h3 className="mb-3">{t("mexico_personal_security_24_h3")}</h3>
            {/* <p>Gain access to 21st century technology and information to have security on your side and reduce the odds of paying costly ransoms.</p> */}
          </div>
          </div>
        </div>
        </div>
      </div>
      </div>
    </div>
  </section>



   {/* <!-- Parallax Section --> */} 
<section className="banner-mexico-1">
 
</section>

  {/* <section className="section safety-section">

    <div className="container">
      <div className="row justify-content-center text-center mb-5" data-aos="fade">
        
        <h2 className="section-heading">{t("mexico_improving_safety_h3")}</h2>

      </div>

      <div className="row">
        <div className="col-md-4">
          <div className="step">
            <span className="number">01</span>
            <h3>{t("mexico_improving_safety_signup_h3")}</h3>
            <p>{t("mexico_improving_safety_signup_p")}<br /><br /></p>
          </div>
        </div>
        <div className="col-md-4">
          <div className="step">
            <span className="number">02</span>
            <h3>{t("mexico_improving_create_zone_h3")}</h3>
            <p>{t("mexico_improving_create_zone_p")} <br /><br /> </p>
          </div>
        </div>
        <div className="col-md-4">
          <div className="step">
            <span className="number">03</span>
            <h3>{t("mexico_improving_stay_safe_h3")}</h3>
            <p>{t("mexico_improving_stay_safe_p")}<br /><br /></p>
          </div>
        </div>
        
      </div>

      <div className="row justify-content-center text-center mb-5" data-aos="fade">
        <div className="col-md-6 mb-5 safety-section-photo">
          <img src={TetheredPhoneAustinPreview} alt="icon" className="img-fluid" />
        </div>
      </div>
    </div>

  </section> */}

    {/* <!-- Parallax Section --> */}
    {/* <section className="banner2">
    </section> */}

  <section className="section">
    <div className="container">
      <div className="row align-items-center home-section">
        <h2 className="section-heading" id="stats-visibility-element">{t("mexico_power_h2")}</h2>
          <div className="me-auto home-power home-power-top " id="home-stats">
          {/* home-stats-hide */}

           {/* <div className="home-stats-container">
            <p><span id="visibilityFeedback"></span></p>
              
            <div className="numbers">
              
              <span className="numbers__window">
              <span className="numbers__window__digit numbers__window__digit--1" data-fake="2642519073">+</span>
              </span> 
              <span className="numbers__window">
              <span className="numbers__window__digit numbers__window__digit--2" data-fake="9207186394">4</span>
              </span>
              <span className="numbers__window">
              <span className="numbers__window__digit numbers__window__digit--3" data-fake="9395216407">9</span>
              </span>
              <span className="numbers__window">
              <span className="numbers__window__digit numbers__window__digit--4" data-fake="4395216407">0</span>
              </span>
              <span className="numbers__window">
              <span className="numbers__window__digit numbers__window__digit--4" data-fake="+395216407">%</span>
              </span>
              <br /> 
              <span className="numbers__window word__window">
              <span>Increase In Kidnapping</span>
              </span>
          
            </div>

            <p >Between 2017 and 2020 in Nigeria. Mass abductions area increasing dramatically year over year. </p>

              <div className="text-align-right">
                <p > <span className="italics source bold">- HumAngleMedia.com</span> </p>
              </div>
            <br /> 
            <p >Security forces rescued 6 of 32 people abducted at a train station in Edo state...Insecurity is one of the key issues for candidates ahead of Nigeria's general elections in February [2023] when a successor to President Muhammadu Buhari will be chosen. </p>
            <div className="text-align-right">
              <p > <span className="italics source bold">- BBC News, January 2023</span> </p>
              <br />
            </div>

          </div> */}

            {/* <div className="home-stats-container">
              
              <div className="numbers ">
              
              <span className="numbers__window">
              <span className="numbers__window__digit numbers__window__digit--1" data-fake="2642519073">+</span>
              </span> 
              <span className="numbers__window">
              <span className="numbers__window__digit numbers__window__digit--2" data-fake="9207186394">5</span>
              </span>
              <span className="numbers__window">
              <span className="numbers__window__digit numbers__window__digit--3" data-fake="9395216407">1</span>
              </span>
              <span className="numbers__window">
              <span className="numbers__window__digit numbers__window__digit--4" data-fake="4395216407">4</span>
              </span>
              <span className="numbers__window">
              <span className="numbers__window__digit numbers__window__digit--4" data-fake="+395216407">4</span>
              </span>
              <br /> 
              <span className="numbers__window word__window">
              <span  >Abducted <br /> 2022</span>
              </span>
          
            </div>

            <p > Targeting rural dwellers and travellers across the region in what some analysts say is one of the most lucrative kidnap-for-ransom syndicates in the continent. </p>

              <div className="text-align-right">
                <p > <span className="italics source bold">- The Premium Times, Nigeria</span> </p>
              </div>

            <br /> <p>...Nigeria occupies the second position on the list of the top 10 world kidnap for ransom risk countries </p>
            
            <div className="text-align-right">
              <p > <span className="italics source bold">- Muhammad Ribadu Ayuba, June 2020</span> </p>
              <br />
            </div>

            </div> */}
           
            {/* <div className="home-stats-container third-box">
              
              <div className="numbers ">
              
              <span className="numbers__window">
              <span className="numbers__window__digit numbers__window__digit--1" data-fake="2642519073">+</span>
              </span> 
              <span className="numbers__window">
              <span className="numbers__window__digit numbers__window__digit--2" data-fake="9207186394">$</span>
              </span>
              <span className="numbers__window">
              <span className="numbers__window__digit numbers__window__digit--3" data-fake="9395216407">1</span>
              </span>
              <span className="numbers__window">
              <span className="numbers__window__digit numbers__window__digit--3" data-fake="9395216407">8</span>
              </span>
              
            <br /> 
              <span className="numbers__window word__window">
                <span  >Million USD Ransom Paid</span>
              </span>
          
          </div>
              <p >Between 2011-2020. Kidnapping for ransom is the main form of kidnapping, and they’re carefully planned, organized and executed by various organized criminal groups </p>
               <div className="text-align-right">
                <p > <span className="italics source bold">- The Conversation, June 17, 2022</span> </p>
              </div>
                <br /> */}
            {/* <p><a href="/contact-us" className="btn btn-primary">Coming Soon...</a></p> */}
            {/* <a href="https://play.google.com/store/apps/details?id=com.tetheredgps.tethered" > <img src={GooglePlayBadge} className="google-play-badge" alt="app-badge" /> </a> */}
            {/* Get The App */}
            {/* <br />
          </div> */}
        </div>
      </div>
    </div>
  </section>

  <section className="section">
    <div className="container">
      <div className="row align-items-center home-section">
        <div className="col-md-4 ms-auto order-2 home-power">
          <h3 className="mb-4">{t("mexico_power_why_h3")}</h3>
          <p className="p-mod">{t("mexico_power_why_p_part1")} <br /> <br />

            {t("mexico_power_why_p_part2")} <br /> <br />
            
            {t("mexico_power_why_p_part3")} <br /> <br />
            
            {t("mexico_power_why_p_part4")}</p>
            <br />
          {/* <p><a href="/contact-us" className="btn btn-primary">Coming Soon...</a></p> */}
          {/* <a href="https://play.app.goo.gl/?link=https://play.google.com/store/apps/details?id=com.tetheredgps.tethered" target="_blank" rel="noopener noreferrer"> <img src={GooglePlayBadge} className="google-play-badge" alt="app-badge" /> </a> */}
          {/* Get The App */}
        </div>
        <div className="col-md-6 home-images" data-aos="fade-right">
          <img src={Mexico1} alt="icon" className="img-fluid img-home" />
        </div>
      </div>
    </div>
  </section>

  <section className="section">
    <div className="container">
      <div className="row align-items-center home-section">
        <div className="col-md-4 me-auto home-power">
          <h3 className="mb-4">{t("mexico_power_mexico_h3")}</h3>
          <p className="p-mod"> {t("mexico_power_mexico_p_part1")}  
            
            <br /> <br />
            {t("mexico_power_mexico_p_part2")}
            <br /> <br />
            {t("mexico_power_mexico_p_part3")}
            </p>
            <br />
          {/* <p><a href="/contact-us" className="btn btn-primary">Coming Soon...</a></p> */}
          {/* <a href="https://play.app.goo.gl/?link=https://play.google.com/store/apps/details?id=com.tetheredgps.tethered" target="_blank" rel="noopener noreferrer"> <img src={GooglePlayBadge} className="google-play-badge" alt="app-badge" /> </a> */}
          {/* Get The App */}
        </div>
        <div className="col-md-6 home-images" data-aos="fade-left">
          <img src={Mexico2} alt="icon" className="img-fluid img-home mobile-off" />
        </div>
      </div>
    </div>
  </section>

  <section className="section">
    <div className="container">
      <div className="row align-items-center home-section">

        <div className="col-md-4 ms-auto order-2 home-power">
          <h3 className="mb-4">{t("mexico_power_enterprise_h3")}</h3>
          <h5>{t("mexico_power_enterprise_h5")}</h5>
          <br></br>
          <p className="p-mod">{t("mexico_power_enterprise_p_part1")} <br /> <br />

            {t("mexico_power_enterprise_p_part2")} <br /> <br />
            
            {t("mexico_power_enterprise_p_part3")} <br /> <br />
            
            {t("mexico_power_enterprise_p_part4")} <br /> <br />
            
            {t("mexico_power_enterprise_p_part5")}</p>
            <br />

          {/* <p><a href="/contact-us" className="btn btn-primary">Contact Sales</a></p> */}
          {/* Enterprise Solutions */}

          <div className="badges-container">



            <div className="badges-container-flex badges-container-flex-tablet">

              <div className="google-badge-container">
                <a href="https://play.app.goo.gl/?link=https://play.google.com/store/apps/details?id=com.tetheredgps.tethered" target="_blank" rel="noopener noreferrer"> <img src={GooglePlayBadge} className="google-play-badge" alt="app-badge" /> </a>
              </div>
                
              <div className="apple-badge-container">
                <a href="https://apps.apple.com/us/app/tethered-gps/id6450391042" > <img src={AppleAppBadge} className="appple-app-badge" alt="app-badge" /> </a> 
              </div>

            </div>
                


            </div>

        </div>
        <div className="col-md-6 home-images" data-aos="fade-right">
          <img src={Mexico3} alt="icon" className="img-fluid img-home" />
        </div>

      </div>
    </div>
  </section>

 {/* <!-- Parallax Section --> */}
   
    {/* <section className="banner-american-2"> 
   </section>  */}
  
</main>
{/* <!-- End #main --> */}

{/* <!-- Template Main JS File --> */}
<script src="scripts/stats-counter.js"></script>

{/* <!-- Newsfeed/Testimonial Slider JS File --> */}
 <script src="scripts/testimonials.js"></script>

</div>
    
  )
}
 