import { BrowserRouter, Route, Routes, Navigate } from 'react-router-dom'
import { useAuthContext } from './hooks/useAuthContext.js'
import { HeaderScroll } from "./scripts/headerScroll.js"
import { StatsCounter } from "./scripts/stats-counter.js"
import { HamburgerNav } from "./scripts/hamburgerNav.js"
import { useEffect, useState } from 'react';
import React from 'react';

// styles
import './App.css'

// Vendor CSS Files
// import './vendor/aos/aos.css'
import "./vendor/bootstrap/css/bootstrap.css"
import "./vendor/bootstrap-icons/bootstrap-icons.css"
import "./vendor/boxicons/css/boxicons.min.css"
import "./vendor/swiper/swiper-bundle.min.css"

// pages and componeents
import Homepage from './pages/homepage/Homepage.js'
import Solutions from './pages/solutions/Solutions.js'
import Login from './pages/login/Login.js'
import ForgotPassword from './pages/forgot-password/ForgotPassword.js'
import ContactUs from './pages/contact-us/Contact-Us.js'
import AboutUs from './pages/about-us/About-Us.js'
import Jobs from './pages/jobs/Jobs.js'
import FAQ from './pages/faq/FAQ.js'
import PrivacyPolicy from './pages/privacy-policy/PrivacyPolicy.js'
import CookiesPolicy from './pages/cookies-policy/CookiesPolicy.js'
import TermsAndConditions from './pages/terms-and-conditions/TermsAndConditions.js'
import Signup from './pages/signup/Signup.js'
import UnitedStates from './pages/united-states/United-States.js'
import LatinAmerica from './pages/latin-america/Latin-America.js'
import Nigeria from './pages/nigeria/Nigeria.js'
import Mexico from './pages/mexico/Mexico.js'
import Navbar from './components/Navbar.js'
import Footer from './components/Footer.js'
import TutorialCreateSafetyZone from './pages/tutorials/Tutorial-create-a-geofence.js'
import TutorialCreateContact from './pages/tutorials/Tutorial-create-a-contact.js'
import TutorialAddDocument from './pages/tutorials/Tutorial-add-a-document.js'
import TutorialActivatePanicButton from './pages/tutorials/Tutorial-activate-panic.js'
import TutorialRespondToAlert from './pages/tutorials/Tutorial-respond-to-alert.js'
import TutorialHowToHideApp from './pages/tutorials/Tutorial-how-to-hide-app.js'
import TutorialBiometricLogin from './pages/tutorials/Tutorial-biometric-login.js'
import TutorialStartPauseMonitoring from './pages/tutorials/Tutorial-start-pause-monitoring.js'
import TutorialContactAgent from './pages/tutorials/Tutorial-contact-agent.js'
import TutorialMembershipSetup from './pages/tutorials/Tutorial-membership-setup.js'


function App() {
  const { user, authIsReady } = useAuthContext()
  const [logged, setLogged] = useState(false)
  
  useEffect(() => {
    let token = window.location.search.split("=")[1];
    if (token === "ANXNmBettCJmkKf0ULuaQKSXmvJ9J1AGHJwfpQoKBXU8qiTmvXgqr6obcdNO") {
      setLogged(true)
    } else {
      setLogged(false)
    }
  }, [window.location.pathname])

  return (
    <div className="App">

      {HeaderScroll()}
      {/* {StatsCounter()} */}
      {HamburgerNav()}

      {authIsReady && (

        <BrowserRouter>

          <div className="app-container">

            <Navbar />
            
            <Routes>
              {/* PAGES AVAILABLE TO ALL USERS: */}
              <Route exact path="/" element = {<Homepage />} />

              <Route exact path="/solutions" element = {<Solutions />} />
              {/* Solutions */}

              <Route exact path="/contact-us" element = {<ContactUs />} />

              <Route exact path="/jobs" element = {<Jobs />} />

              <Route exact path="/about-us" element = {<AboutUs />} />

              <Route exact path="/faq" element = {<FAQ />} />

              <Route exact path="/privacy-policy" element = {<PrivacyPolicy />} />

              <Route exact path="/cookies-policy" element = {<CookiesPolicy />} />

              <Route exact path="/terms-and-conditions" element = {<TermsAndConditions />} />

              <Route exact path="/united-states" element = {<UnitedStates />} />
              
              <Route exact path="/latin-america" element = {<LatinAmerica />} />

              <Route exact path="/nigeria" element = {<Nigeria />} />

              <Route exact path="/mexico" element = {<Mexico />} />
             
              <Route exact path="/tutorials-create-a-safety-zone" element = {<TutorialCreateSafetyZone />} />

              <Route exact path="/tutorials-create-a-contact" element = {<TutorialCreateContact />} />
              
              <Route exact path="/tutorials-add-a-document" element = {<TutorialAddDocument />} />

              <Route exact path="/tutorials-activate-panic-button" element = {<TutorialActivatePanicButton />} />
              
              <Route exact path="/tutorials-respond-to-alert" element = {<TutorialRespondToAlert />} />

              <Route exact path="/tutorials-how-to-hide-the-app" element = {<TutorialHowToHideApp />} />
              
              <Route exact path="/tutorials-biometric-login" element = {<TutorialBiometricLogin />} />

              <Route exact path="/tutorials-start-pause-monitoring" element = {<TutorialStartPauseMonitoring />} />

              <Route exact path="/tutorials-contact-agent" element = {<TutorialContactAgent />} />

              <Route exact path="/tutorials-membership-setup" element = {<TutorialMembershipSetup />} />

              <Route exact path="/login" element = {<Login />} />

              <Route exact path="/forgot-password" element = {<ForgotPassword />} />

              <Route exact path="/signup" element = {<Signup />} />


             


            </Routes>

            <Footer />

          </div>

        </BrowserRouter>
        )
      }
    </div>
  );
}

export default App
