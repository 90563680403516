import firebase from 'firebase/compat/app'
import 'firebase/compat/firestore'
import 'firebase/compat/auth'
import 'firebase/compat/storage'
import 'firebase/compat/app-check'

// {/* Official TETHERED Firebase API */}
  // For Firebase JS SDK v7.20.0 and later, measurementId is optional
  const firebaseConfig = {
    apiKey: "AIzaSyAO8qnuhAqLDpHNUaqWD_43qlaloEijgOo",
    authDomain: "tethered-934bf.firebaseapp.com",
    databaseURL: "https://tethered-934bf-default-rtdb.firebaseio.com",
    projectId: "tethered-934bf",
    storageBucket: "tethered-934bf.appspot.com",
    messagingSenderId: "263787334945",
    appId: "1:263787334945:web:9271b541eb4add43f90260",
    measurementId: "G-J3PKJYXEXT"
  };

// init firebase
firebase.initializeApp(firebaseConfig)

// init services
const projectFirestore = firebase.firestore()
const projectAuth = firebase.auth()
const projectStorage = firebase.storage()

// timestamp
const timestamp = firebase.firestore.Timestamp

// Firebase app check

const appCheck = firebase.appCheck()
// Pass your reCAPTCHA v3 site key (public key) to activate(). Make sure this
// key is the counterpart to the secret key you set in the Firebase console.
appCheck.activate(
  '6LdhOnckAAAAAO5SSkiM3XJLfgODyUBqVT7JR3H1')

export { projectFirestore, projectAuth, timestamp, projectStorage}