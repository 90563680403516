import {useState} from 'react'
import {useSignup} from '../../hooks/useSignup.js'

// styles
import './Signup.css'

import { useTranslation } from "react-i18next";

export default function Signup() {

  const { t } = useTranslation();

  const [email, setEmail] = useState('')
  const [password, setPassword] = useState('')
  // const [displayName, setDisplayName] = useState ('')
  // const [thumbnail, setThumbnail] = useState (null)
  // const [thumbnailError, setThumbnailError] = useState (null)
  const {signup, isPending, error} = useSignup()

  const handleSubmit = (e) => {
    e.preventDefault()
    signup(email, password)
  }

  // const handleFileChange = (e) => {
  //   setThumbnail(null)
  //   let selected = e.target.files[0]
  //   console.log(selected)

  //   if(!selected) {
  //     setThumbnailError ('Please select a file')
  //     return
  //   }
  //   if (!selected.type.includes('image')) {
  //     setThumbnailError ('Please select an image file')
  //     return
  //   }
  //   if (selected.size > 500000) {
  //     setThumbnailError ('Image file size must be lass than 0.5mb')
  //     return
  //   }

  //   setThumbnailError(null)
  //   setThumbnail(selected)
  //   console.log('thumbnail updated') 

  // }

  return (
    <div>  
    {/* background section */}
 <section className="background-login">
    <form className="auth-form" onSubmit={handleSubmit}>
      
      <h2>{t("signup_h2_signup")}</h2>
      <br></br>
      <label>
        <span>{t("signup_email")} </span>
        <input 
          required 
          type="email" 
          onChange={(e) => setEmail(e.target.value)}
          value={email}
          /> 
      </label>
      <label>
        <span>{t("signup_password")} </span>
        <input 
          required 
          type="password" 
          onChange={(e) => setPassword(e.target.value)}
          value={password}
          /> 
      </label>
      {/* <label>
        <span>display name: </span>
        <input 
          required 
          type="text" 
          onChange={(e) => setDisplayName(e.target.value)}
          value={displayName}
          /> 
      </label>
      <label>
        <span>profile thumbnail: </span>
        <input 
          required 
          type="file" 
          onChange = {handleFileChange}
          /> 
          {thumbnailError && <div className="error">{thumbnailError}</div>}
      </label> */}
      <br></br>
      {!isPending && <button id="sendBtn" type="submit" className="form-control btn btn-primary">{t("signup_button_signup")}</button> } 
        {isPending && <button className="form-control btn btn-primary" disabled>{t("signup_button_loading")}</button> }
      {error && <div className="error">{error}</div>}
    </form>
    </section>  
    </div>
  )
}
 