// styles
import './Login.css'

import {useState} from 'react'
import {useLogin} from '../../hooks/useLogin.js'
import Logo from '../../assets/img/tethered_banner.png'

import { useTranslation } from "react-i18next";


export default function Login() {

const [email, setEmail] = useState('')
const [password, setPassword] = useState('')
const {login, isPending, error, forgotPassword} = useLogin()

const { t } = useTranslation();

const handleSubmit = (e) => {
  e.preventDefault()
  login(email, password)
}


  return (
  <div>  
     {/* background section */}
  <section className="background-login"> 

    <form className="auth-form" onSubmit={handleSubmit}>
 
      <div className ="login-logo">
                <img src = {Logo} alt ="logo" />
                <span>  </span>
            </div>
      <br></br>
      <label>
        <span>{t("login_email_label")} </span>
      <input 
        required 
        type="email" 
        onChange={(e) => setEmail(e.target.value)}
        value={email}
        /> 
      </label>
      <label>
      <span>{t("login_password_label")} </span>
      <input 
        required 
        type="password" 
        onChange={(e) => setPassword(e.target.value)}
        value={password}
        /> 
        
      </label>
      <br></br>
        {!isPending && <button id="sendBtn" type="submit" className="form-control btn btn-primary">{t("login_login_label")}</button> } 
        {isPending && <button className="form-control btn btn-primary" disabled>{t("login_loading_label")} </button> }
        {error && <div className="error">{error}</div>}
        <br></br>
        {/* <button href="/forgot-password">Forgot Password?</button> */}
        <a href="/forgot-password" className="forgot-password" >{t("login_forgot_password_label")}</a>
        {/* <button onClick={forgotPasswordHandler}>Forgot Password?</button> */}
    </form>

  </section> 
  </div>
  )
}




 